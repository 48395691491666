import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { OfflineProcessPosition } from '../../../shared/configs/offline-process-position.config';
import { OfflineProcessType } from '../../../shared/configs/offline-process-type.config';
import { CustomError } from '../../../shared/models/api-response';
import { HelperService } from '../../services/helper/helper.service';
import { AuthService } from '../../services/http/auth/auth.service';
import { OfflineService } from '../../services/http/offline/offline.service';
import { ValidationService } from '../../services/validation/validation.service';

import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnDestroy {
  centreActivationForm: FormGroup;
  isActivationButtonClicked: boolean = false;
  isCentreActivated: boolean = false;
  isFirstPage: boolean = true;
  timelineList: any[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private offlineService: OfflineService,
    private router: Router,
    private validationService: ValidationService,
  ) {
    this.centreActivationForm = this.createFormGroup();
    this.timelineList = this.initializeTimelineList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickSubmit(): void {
    this.isActivationButtonClicked = true;
    this.offlineService
      .setOfflineLog(OfflineProcessPosition.start, OfflineProcessType.duringActivation, [422])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.activateCentre();
        },
        error: (err: CustomError) => {
          // Error
          this.isActivationButtonClicked = false;
          if (err.statusCode === 422 && err.errors.update?.length !== 0) {
            this.helperService.showMessage('info', err.errors.update[0]);
          }
        },
      });
  }

  private activateCentre(): void {
    this.offlineService
      .activateCentre(this.centreActivationForm.value)
      .pipe(
        switchMap((res) => {
          this.helperService.showMessage('success', res.message);
          this.isFirstPage = false;

          return this.offlineService.downloadAssets();
        }),
        switchMap(() => {
          this.timelineList[0].icon = 'assets/images/offline/completed-tick.svg';

          return this.offlineService.downloadCentreUsers();
        }),
        switchMap(() => {
          this.timelineList[1].icon = 'assets/images/offline/completed-tick.svg';

          return this.offlineService.downloadUserActivities();
        }),
        switchMap(() => {
          this.timelineList[2].icon = 'assets/images/offline/completed-tick.svg';

          return this.offlineService.downloadCentreCommunities();
        }),
        switchMap(() => {
          this.timelineList[3].icon = 'assets/images/offline/completed-tick.svg';

          return this.offlineService.downloadCentreVacancies();
        }),
        switchMap(() => {
          this.timelineList[4].icon = 'assets/images/offline/completed-tick.svg';

          return this.offlineService.downloadCentreNotifications();
        }),
        switchMap(() => {
          this.timelineList[5].icon = 'assets/images/offline/completed-tick.svg';
          this.isCentreActivated = true;

          return this.offlineService.setOfflineLog(
            OfflineProcessPosition.end,
            OfflineProcessType.duringActivation,
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        () => {
          this.authService.logoutLocally();
          this.router.navigate(['login']);
        },
        (err: CustomError) => {
          this.isActivationButtonClicked = false;
          if (err?.message) {
            this.helperService.showMessage('error', err.message);
          }
          this.validationService.addResponseErrorToForm(this.centreActivationForm, err);
        },
      );
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      activation_code: [
        null,
        [Validators.required, Validators.maxLength(environment.maxCharLimit)],
      ],
    });
  }

  private initializeTimelineList(): any[] {
    return [
      {
        status: 'offline.asset_downsync_success',
        icon: '',
        position: '1',
      },
      {
        status: 'offline.centre_users_downsync_success',
        icon: '',
        position: '2',
      },
      {
        status: 'offline.students_activity_downsync_success',
        icon: '',
        position: '3',
      },
      {
        status: 'offline.centre_community_downsync_success',
        icon: '',
        position: '4',
      },
      {
        status: 'offline.centre_vacancy_downsync_success',
        icon: '',
        position: '5',
      },
      {
        status: 'offline.notification_downsync_success',
        icon: '',
        position: '6',
      },
    ];
  }
}
