import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';

import { UserDetailsService } from '../../services/user-details/user-details.service';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CentreSelectionGuard implements CanActivate, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private router: Router, private userDetailsService: UserDetailsService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserAllowed();
  }

  private isUserAllowed(): boolean | UrlTree {
    if (
      this.userDetailsService.userDetails.is_super_facilitator === 1 &&
      !this.userDetailsService.userDetails.isCentreSelected
    ) {
      // Redirect to switch centre page if user is super facilitator and centre is not selected
      return this.router.parseUrl('/switch-centre');
    }

    return true;
  }
}
