<div class="core-container" fxLayout="column" fxLayoutAlign="center center">
  <!-- Language Selection -->
  <div class="language-select" fxFlexAlign="end">
    <app-language-select></app-language-select>
  </div>

  <div class="spacer" fxHide fxShow.xs></div>

  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Welcome -->
  <h1 class="core-main-title">{{ 'registration_stage_2.welcome' | translate }}</h1>

  <!-- Registration Form Stepper -->
  <mat-stepper
    #stepper
    [orientation]="mediaObserver.isActive('lt-md') ? 'vertical' : 'horizontal'"
    [linear]="true"
    [formGroup]="registrationForm"
  >
    <ng-template matStepperIcon="edit" let-index="index"> {{ index + 1 }} </ng-template>

    <!-- Step 1: Personal Details -->
    <mat-step
      [stepControl]="registrationForm.get(['personalDetails'])!"
      [completed]="registrationForm.get(['personalDetails'])?.valid"
      [label]="'registration_stage_2.enter_personal_details' | translate"
    >
      <div fxLayout fxLayoutAlign="center center">
        <mat-card>
          <!-- Card Title -->
          <mat-card-header>
            <mat-card-title>
              <h3>{{ 'registration_stage_2.personal_details' | translate }}</h3>
            </mat-card-title>
          </mat-card-header>

          <!-- Card Content -->
          <mat-card-content>
            <form class="dialog-form" formGroupName="personalDetails">
              <!-- Row 1 -->
              <div class="dialog-row">
                <!-- Name -->
                <div class="p-field col-2">
                  <span class="p-float-label required">
                    <input type="text" pInputText formControlName="name" />
                    <label>{{ 'registration_stage_2.name' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['personalDetails', 'name'])"
                    [field]="'registration_stage_2.name' | translate"
                  ></app-validation-error>
                </div>

                <!-- DOB -->
                <div class="p-field col-2">
                  <div class="p-inputgroup">
                    <span class="p-float-label p-input-icon-right required">
                      <input
                        readonly
                        pInputText
                        formControlName="date_of_birth"
                        [matDatepicker]="dateOfBirth"
                        [max]="today"
                        (click)="dateOfBirth.open()" />
                      <label>{{ 'registration_stage_2.dob' | translate }}</label>
                      <i
                        class="pi pi-times"
                        (click)="
                          registrationForm.get(['personalDetails', 'date_of_birth'])?.reset()
                        "
                        *ngIf="
                          registrationForm.get(['personalDetails', 'date_of_birth'])?.value != null
                        "
                      ></i
                    ></span>
                    <span class="p-inputgroup-addon datepicker">
                      <mat-datepicker-toggle [for]="dateOfBirth"></mat-datepicker-toggle>
                      <mat-datepicker
                        #dateOfBirth
                        [touchUi]="mediaObserver.isActive('lt-md')"
                      ></mat-datepicker>
                    </span>
                  </div>
                  <app-validation-error
                    [control]="registrationForm.get(['personalDetails', 'date_of_birth'])"
                    [field]="'registration_stage_2.dob' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Row 2 -->
              <div class="dialog-row" fxLayoutGap="15px">
                <!-- Gender -->
                <div class="p-field col-2">
                  <span class="p-float-label required">
                    <p-dropdown
                      formControlName="gender"
                      optionLabel="name"
                      optionValue="id"
                      appendTo="body"
                      [options]="masterListsObj.gender"
                      [autoDisplayFirst]="false"
                    >
                    </p-dropdown>
                    <label>{{ 'registration_stage_2.gender' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['personalDetails', 'gender'])"
                    [field]="'registration_stage_2.gender' | translate"
                  ></app-validation-error>
                </div>

                <!-- Email -->
                <div class="p-field col-2">
                  <span
                    class="p-float-label"
                    [class.required]="
                      registrationForm.get(['personalDetails', 'mobile'])?.value == null ||
                      registrationForm.get(['personalDetails', 'mobile'])?.value === ''
                    "
                  >
                    <input type="text" pInputText formControlName="email" />
                    <label>{{ 'registration_stage_2.email' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['personalDetails', 'email'])"
                    [field]="'registration_stage_2.email' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Row 3 -->
              <div class="dialog-row">
                <!-- Mobile -->
                <div class="p-field col-2">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">+91</span>
                    <span
                      class="p-float-label"
                      [class.required]="
                        registrationForm.get(['personalDetails', 'email'])?.value == null ||
                        registrationForm.get(['personalDetails', 'email'])?.value === ''
                      "
                    >
                      <input
                        type="text"
                        pInputText
                        formControlName="mobile"
                        (keypress)="onKeypress($event)"
                      />
                      <label>{{ 'registration_stage_2.mobile' | translate }}</label>
                    </span>
                  </div>
                  <app-validation-error
                    [control]="registrationForm.get(['personalDetails', 'mobile'])"
                    [field]="'registration_stage_2.mobile' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Action Buttons -->
              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="space-between center"
                fxLayoutGap="15px"
              >
                <!-- Change Account Type -->
                <button
                  type="button"
                  mat-button
                  color="primary"
                  fxFlexOrder="0"
                  fxFlexOrder.xs="2"
                  routerLink="/register"
                  [queryParams]="{ type: userTypeId }"
                >
                  <mat-icon>arrow_back</mat-icon>
                  {{ 'registration_stage_2.change_account_type' | translate }}
                </button>

                <!-- Continue -->
                <button
                  type="submit"
                  mat-flat-button
                  color="primary"
                  fxFlexOrder="0"
                  fxFlexOrder.xs="1"
                  [disabled]="registrationForm.get(['personalDetails'])?.invalid"
                  (click)="onClickSendOtp()"
                  *ngIf="
                    !registrationForm.get(['personalDetails'])?.pending && !isSendingOtp;
                    else spinner
                  "
                >
                  {{ 'common.continue' | translate }}
                </button>
                <ng-template #spinner>
                  <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
                </ng-template>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-step>

    <!-- Step 2: Verification -->
    <mat-step
      [stepControl]="registrationForm.get(['verification'])!"
      [completed]="registrationForm.get(['verification'])?.valid"
      [label]="'registration_stage_2.verification' | translate"
      *ngIf="!isOffline"
    >
      <div fxLayout fxLayoutAlign="center center">
        <mat-card>
          <!-- Card Title -->
          <mat-card-header>
            <mat-card-title>
              <h3>{{ 'registration_stage_2.enter_otp' | translate }}</h3>
            </mat-card-title>
          </mat-card-header>
          <!-- Card Content -->
          <mat-card-content>
            <form
              fxLayout="column"
              fxLayoutAlign="space-between stretch"
              fxLayoutGap="5px"
              formGroupName="verification"
            >
              <!-- Heading 1 -->
              <div fxFlexOffset="30px" fxLayoutAlign="center" fxLayoutAlign.md="center ">
                <h3>
                  {{
                    'login.enter_otp'
                      | translate
                        : {
                            name:
                              (registrationForm.get(['personalDetails'])?.value?.email == null ||
                                registrationForm.get(['personalDetails'])?.value?.email === '') &&
                              registrationForm.get(['personalDetails'])?.value?.mobile != null
                                ? registrationForm
                                    .get(['personalDetails'])
                                    ?.value?.mobile?.slice(0, 4) + '******'
                                : (registrationForm.get(['personalDetails'])?.value?.mobile ==
                                    null ||
                                    registrationForm.get(['personalDetails'])?.value?.mobile ===
                                      '') &&
                                  registrationForm.get(['personalDetails'])?.value?.email != null
                                ? registrationForm
                                    .get(['personalDetails'])
                                    ?.value?.email?.slice(0, 4) + '******'
                                : registrationForm
                                    .get(['personalDetails'])
                                    ?.value?.email?.slice(0, 4) +
                                  '******/' +
                                  registrationForm
                                    .get(['personalDetails'])
                                    ?.value?.mobile?.slice(0, 4) +
                                  '******'
                          }
                  }}
                </h3>
              </div>
              <!-- OTP -->
              <div fxLayoutAlign="center" fxFlexOffset="30px">
                <div fxFlex="85px">
                  <p-inputMask
                    placeholder="X-X-X-X"
                    formControlName="otp"
                    mask="9-9-9-9"
                    slotChar="X"
                    [unmask]="true"
                  ></p-inputMask>
                  <app-validation-error
                    [control]="registrationForm.get(['verification', 'otp'])"
                    [field]="'registration_stage_2.otp' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Button Row -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-around center"
                fxLayoutGap.gt-sm="10px"
                class="verification-buttons"
              >
                <!-- Resend OTP -->
                <button
                  mat-flat-button
                  color="accent"
                  (click)="onClickSendOtp()"
                  *ngIf="resendOtpTimeLeft === 0; else otpTimer"
                >
                  {{ 'login.resend_otp' | translate }}
                </button>

                <!-- Otp Timer -->
                <ng-template #otpTimer>
                  <span class="otp-timer">
                    {{
                      'login.resend_otp_in'
                        | translate: { time: (resendOtpTimeLeft * 1000 | date: 'mm:ss':'UTC') }
                    }}
                  </span>
                </ng-template>

                <!-- Next -->
                <button
                  type="submit"
                  mat-flat-button
                  color="primary"
                  fxFlexOrder="0"
                  fxFlexOrder.xs="1"
                  [disabled]="registrationForm.get(['verification'])?.invalid"
                  *ngIf="!isLoading; else spinner"
                  (click)="onClickVerifyOtp()"
                >
                  {{ 'common.continue' | translate }}
                </button>

                <ng-template #spinner>
                  <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
                </ng-template>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-step>

    <!-- Step 3: Centre Details -->
    <mat-step
      [stepControl]="registrationForm.get(['centerDetails'])!"
      [completed]="registrationForm.get(['centerDetails'])?.valid"
      [label]="'registration_stage_2.enter_centre_details' | translate"
      [aria-labelledby]="!isVerifyOtp ? 'verify' : ''"
    >
      <div fxLayout fxLayoutAlign="center center">
        <mat-card>
          <!-- Card Title -->
          <mat-card-header>
            <mat-card-title>
              <h3>{{ 'registration_stage_2.centre_details' | translate }}</h3>
            </mat-card-title>
          </mat-card-header>
          <div class="line"></div>
          <!-- Card Content -->
          <mat-card-content>
            <form class="dialog-form" formGroupName="centerDetails">
              <div class="dialog-row">
                <!-- State -->
                <div class="p-field col-2">
                  <span class="p-float-label required">
                    <p-dropdown
                      formControlName="state"
                      optionLabel="name"
                      optionValue="id"
                      filterBy="name"
                      filterMatchMode="startsWith"
                      appendTo="body"
                      [options]="masterListsObj.state"
                      [autoDisplayFirst]="false"
                      [filter]="true"
                      [resetFilterOnHide]="true"
                      (onChange)="onChangeState($event)"
                    >
                    </p-dropdown>
                    <label>{{ 'registration_stage_2.state' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['centerDetails', 'state'])"
                    [field]="'registration_stage_2.state' | translate"
                  ></app-validation-error>
                </div>

                <!-- District -->
                <div class="p-field col-2" *appOffline="'hide'">
                  <span class="p-float-label required">
                    <p-dropdown
                      formControlName="district"
                      optionLabel="name"
                      optionValue="id"
                      filterBy="name"
                      filterMatchMode="startsWith"
                      appendTo="body"
                      [options]="districtList"
                      [autoDisplayFirst]="false"
                      [filter]="true"
                      [resetFilterOnHide]="true"
                      (onChange)="onChangeDistrict($event)"
                    >
                    </p-dropdown>
                    <label>{{ 'registration_stage_2.district' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['centerDetails', 'district'])"
                    [field]="'registration_stage_2.district ' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Training Organisation -->
              <div class="p-field col-2" *appOffline="'show'">
                <span class="p-float-label required">
                  <p-dropdown
                    formControlName="organisation_id"
                    optionLabel="name"
                    optionValue="id"
                    filterBy="name"
                    filterMatchMode="startsWith"
                    appendTo="body"
                    [options]="trainingOrganisationList"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    [resetFilterOnHide]="true"
                    (onChange)="onChangeOrganisation($event)"
                  >
                  </p-dropdown>
                  <label>{{ 'registration_stage_2.training_organisation' | translate }}</label>
                </span>
                <app-validation-error
                  [control]="registrationForm.get(['centerDetails', 'organisation_id'])"
                  [field]="'registration_stage_2.training_organisation' | translate"
                ></app-validation-error>
              </div>

              <div class="dialog-row">
                <!-- Training Centre / Institute -->
                <div class="p-field col-2">
                  <span class="p-float-label required">
                    <p-dropdown
                      formControlName="centre_id"
                      optionLabel="name"
                      optionValue="id"
                      filterBy="name"
                      filterMatchMode="startsWith"
                      appendTo="body"
                      [options]="trainingCentreList"
                      [autoDisplayFirst]="false"
                      [filter]="true"
                      [resetFilterOnHide]="true"
                    >
                    </p-dropdown>
                    <label>{{ 'registration_stage_2.training_centre' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['centerDetails', 'centre_id'])"
                    [field]="'registration_stage_2.training_centre' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Action -->
              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="space-between center"
                fxLayoutGap="15px"
              >
                <!-- Change Personal Details -->
                <button
                  type="button"
                  mat-button
                  color="primary"
                  fxFlexOrder="0"
                  fxFlexOrder.xs="2"
                  (click)="onClickMoveToPersonalDetails(0)"
                >
                  <mat-icon>arrow_back</mat-icon>
                  {{ 'registration_stage_2.change_personal_details' | translate }}
                </button>

                <!-- Continue -->
                <button
                  type="submit"
                  mat-flat-button
                  color="primary"
                  fxFlexOrder="0"
                  fxFlexOrder.xs="1"
                  matStepperNext
                  [disabled]="registrationForm.get(['centerDetails'])?.invalid"
                >
                  {{ 'common.continue' | translate }}
                </button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-step>

    <!-- Step 4: Password -->
    <mat-step
      [stepControl]="registrationForm.get(['password'])!"
      [completed]="registrationForm.get(['password'])?.valid"
      [label]="'registration_stage_2.password' | translate"
    >
      <div fxLayout fxLayoutAlign="center center">
        <mat-card>
          <!-- Card Title -->
          <mat-card-header>
            <mat-card-title>
              <h3>{{ 'registration_stage_2.password' | translate }}</h3>
            </mat-card-title>
          </mat-card-header>

          <!-- Card Content -->
          <mat-card-content>
            <form class="dialog-form" formGroupName="password" (ngSubmit)="onSubmitPasswordForm()">
              <!-- Password -->
              <div fxLayout="row" fxLayoutAlign="center">
                <div class="p-field" fxFlex.gt-sm="50" fxFlex.sm="80" fxFlex.xs="90">
                  <span class="p-float-label required">
                    <p-password
                      formControlName="password"
                      appendTo="body"
                      styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                      [toggleMask]="true"
                      [feedback]="
                        registrationForm.get(['password', 'password'])?.errors?.password != null
                      "
                    >
                      <ng-template pTemplate="content">
                        <ul #passwordOverlay>
                          <li>{{ 'common.password_title' | translate }}:</li>
                          <li>{{ 'common.password_criteria_1' | translate }}</li>
                        </ul>
                      </ng-template>
                    </p-password>
                    <label>{{ 'registration_stage_2.password' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['password', 'password'])"
                    [field]="'registration_stage_2.password' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Confirm Password -->
              <div fxLayout="row" fxLayoutAlign="center">
                <div class="p-field" fxFlex.gt-sm="50" fxFlex.sm="80" fxFlex.xs="90">
                  <span class="p-float-label required">
                    <p-password
                      formControlName="confirm_password"
                      appendTo="body"
                      styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                      [toggleMask]="true"
                      [feedback]="false"
                    ></p-password>
                    <label>{{ 'registration_stage_2.confirm_password' | translate }}</label>
                  </span>
                  <app-validation-error
                    [control]="registrationForm.get(['password', 'confirm_password'])"
                    [field]="'registration_stage_2.confirm_password' | translate"
                  ></app-validation-error>
                </div>
              </div>

              <!-- Action Buttons -->
              <div
                class="actions-password"
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="space-between center"
                fxLayoutGap="15px"
              >
                <!-- Change Centre Details -->
                <button
                  type="button"
                  mat-button
                  color="primary"
                  fxFlexOrder="0"
                  fxFlexOrder.xs="2"
                  matStepperPrevious
                >
                  <mat-icon>arrow_back</mat-icon>
                  {{ 'registration_stage_2.change_centre_details' | translate }}
                </button>

                <!-- Submit -->
                <button
                  type="submit"
                  mat-flat-button
                  color="primary"
                  fxFlexOrder="0"
                  fxFlexOrder.xs="1"
                  [disabled]="registrationForm.get(['password'])?.invalid"
                  *ngIf="!isLoading; else spinner"
                >
                  {{ 'common.continue' | translate }}
                </button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-step>
  </mat-stepper>

  <!-- Have Account -->
  <div class="link-text" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <span>
      {{ 'registration_stage_2.existing_user' | translate }}
    </span>
    <button mat-flat-button color="accent" routerLink="/login">
      {{ 'registration_stage_2.login' | translate }}
    </button>
  </div>

  <!-- Help -->
  <div class="link-text help" fxFlexOffset="15px" (click)="onClickHelp()">
    {{ 'login.help' | translate }}
  </div>
</div>
