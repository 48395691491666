import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { UserDetailsService } from '../../../core/services/user-details/user-details.service';

@Directive({
  selector: '[appRole]',
})
export class RoleDirective implements OnInit {
  roleList!: string[];

  @Input() set appRole(roleList: string[]) {
    this.roleList = roleList;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private userDetailsService: UserDetailsService,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    const hasAccess =
      this.roleList != null ? this.userDetailsService.hasRoles(this.roleList) : true;
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
