import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';

import { UserRoles } from '../../../../../../../admin/src/app/shared/configs/user-roles.config';
import { environment } from '../../../../../environments/environment';
import { FormErrors } from '../../../../shared/configs/form-errors.config';
import { StorageTokens } from '../../../../shared/configs/storage-tokens.config';
import { UserRegistrationType } from '../../../../shared/configs/user-registration-type.config';
import { CustomError } from '../../../../shared/models/api-response';
import { MasterListItem, MasterListsObj } from '../../../../shared/models/master-list';
import { UserRole } from '../../../../shared/models/user-role.dto';
import { HelperService } from '../../../services/helper/helper.service';
import { AuthService } from '../../../services/http/auth/auth.service';
import { CommonService } from '../../../services/http/common/common.service';
import { StorageService } from '../../../services/storage/storage.service';
import { UserDetailsService } from '../../../services/user-details/user-details.service';
import { ValidationService } from '../../../services/validation/validation.service';
import { RaiseFreescoutTicketDialogComponent } from '../../raise-freescout-ticket-dialog/raise-freescout-ticket-dialog.component';

import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('passwordOverlay') passwordOverlayEl!: ElementRef<HTMLElement>;
  @ViewChild('stepper') stepper!: MatStepper;

  districtList: MasterListItem[] = [];
  isLoading: boolean = false;
  isOffline: boolean;
  isSendingOtp: boolean = false;
  isVerifyOtp: boolean = false;
  masterListsObj: MasterListsObj = {};
  registrationForm: FormGroup;
  resendOtpTimeLeft: number = 0;
  today: Date;
  trainingCentreList: MasterListItem[] = [];
  trainingOrganisationList: MasterListItem[] = [];
  userRegistrationType = UserRegistrationType;
  userTypeId: number;

  private destroy$: Subject<void> = new Subject<void>();
  private resendOtpInterval: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private matDialog: MatDialog,
    public mediaObserver: MediaObserver,
    private router: Router,
    private storageService: StorageService,
    private userDetailsService: UserDetailsService,
    private validationService: ValidationService,
  ) {
    this.isOffline = environment.offline;
    this.userTypeId =
      +UserRegistrationType[
        this.activatedRoute.snapshot.paramMap.get('type') as keyof typeof UserRegistrationType
      ];
    this.today = new Date();
    this.registrationForm = this.createRegistrationFormGroup();
  }

  ngOnInit(): void {
    this.fetchDropdowns();
    this.setEventListeners();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormControl(control: AbstractControl | null): FormControl {
    return <FormControl>control;
  }

  onChangeDistrict(e: any): void {
    this.registrationForm.get(['centerDetails', 'centre_id'])?.reset();
    this.trainingCentreList = [];

    if (e.value == null) {
      return;
    }

    this.commonService
      .fetchCentresUnderDistrict(e.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // filtering to allow registration centres
        this.trainingCentreList = res.data.filter((centre) => centre.registration_status === 1);
        this.trainingCentreList.push({
          name: 'I am not part of any VTI/ITI or center',
          id: '1',
        });
      });
  }

  onChangeOrganisation(e: any): void {
    this.registrationForm.get(['centerDetails', 'centre_id'])?.reset();
    this.trainingCentreList = [];

    if (e.value == null) {
      return;
    }

    this.commonService.fetchCentresUnderOrganisation(e.value).subscribe((res) => {
      // filter with registration_status for getting only registration capable centres
      this.trainingCentreList = res?.data.filter((centre) => centre.registration_status === 1);
    });
  }

  onChangeState(e: any): void {
    this.registrationForm.get(['centerDetails', 'centre_id'])?.reset();
    this.districtList = [];
    this.trainingCentreList = [];
    this.trainingOrganisationList = [];

    if (e.value == null) {
      return;
    }

    this.commonService.fetchOrganisationsUnderState(e.value).subscribe((res) => {
      this.trainingOrganisationList = res.data;
    });

    this.commonService.fetchDistrictsUnderState(e.value).subscribe((res) => {
      this.districtList = res.data;
    });
  }

  onClickHelp(): void {
    this.matDialog.open(RaiseFreescoutTicketDialogComponent, {
      id: 'freescout',
      disableClose: true,
      width: '700px',
    });
  }

  onClickMoveToPersonalDetails(index: number): void {
    this.stepper.selectedIndex = index;
    this.registrationForm.get(['verification', 'otp'])?.reset();
  }

  onClickSendOtp(): void {
    this.startTimer();
    if (this.isOffline) {
      return;
    }

    const { email, mobile } = this.registrationForm.get(['personalDetails'])?.value;

    const otpDetails = {
      email,
      mobile,
    };
    this.isSendingOtp = true;
    this.authService
      .sendRegistrationOtp(otpDetails, [422])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isSendingOtp = false;
        }),
      )
      .subscribe({
        next: (res) => {
          // Success
          this.stepper.next();
          this.helperService.showMessage('success', res.message);
        },
        error: (err: CustomError) => {
          // Error
          this.validationService.addResponseErrorToForm(
            <FormGroup>this.registrationForm.get(['personalDetails'])!,
            err,
          );
        },
      });
  }

  onClickVerifyOtp(): void {
    if (this.registrationForm.get(['verification'])?.invalid) {
      return;
    }

    const { email, mobile } = this.registrationForm.get(['personalDetails'])?.value;
    const otp = this.registrationForm.get(['verification', 'otp'])?.value;

    const otpDetails = {
      otp,
      email,
      mobile,
      device: 'browser',
    };

    this.isLoading = true;
    this.authService
      .verifyRegistrationOtp(otpDetails, [422])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          // Success
          this.stepper.next();
          this.isVerifyOtp = true;
          this.helperService.showMessage('success', res.message);
        },
        error: (err: CustomError) => {
          // Error
          this.isVerifyOtp = false;
          this.helperService.showMessage('error', err.errors.message[0]);
          this.validationService.addResponseErrorToForm(
            <FormGroup>this.registrationForm.get(['verification'])!,
            err,
          );
        },
      });
  }

  onKeypress(e: KeyboardEvent): boolean {
    return this.helperService.isNumberKey(e);
  }

  onSubmitPasswordForm(): void {
    if (this.registrationForm.get(['password'])?.invalid) {
      return;
    }

    const formData = this.registrationForm.value;
    let centreId = this.registrationForm.get(['centerDetails', 'centre_id'])?.value;

    if (centreId === '1') {
      centreId = null;
    }

    const registrationDetails = {
      ...formData.personalDetails,
      ...formData.centerDetails,
      ...formData.password,
      date_of_birth: this.helperService.formatDate(
        formData.personalDetails.date_of_birth,
        'yyyy-MM-dd',
      ),
      type: this.userTypeId,
      device: 'browser',
      centre_checked: false,
      organisation_id: null,
      centre_id: centreId,
    };
    this.isLoading = true;
    this.authService
      .registerUser(registrationDetails)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.helperService.showMessage('success', res.message, 10000);

          const isFacilitator = res.data.roles.some((role: UserRole) =>
            [UserRoles.facilitator, UserRoles.masterTrainer, UserRoles.superFacilitator].includes(
              role.name,
            ),
          );
          if (isFacilitator) {
            // Facilitator is navigated to login page
            this.router.navigate(['/quest']);
          } else {
            // Learner is navigated to home page
            this.storageService.addToStorage('local', StorageTokens.accessToken1, res.token);
            this.userDetailsService.setUserDetails(res.data);
            this.router.navigate(['/quest/home']);
          }
        },
        error: (err: CustomError) => {
          this.validationService.addResponseErrorToForm(
            <FormGroup>this.registrationForm.get(['personalDetails']),
            err,
          );
        },
      });
  }

  private createCenterDetailsFormGroup(): FormGroup {
    const centerDetailsForm = this.formBuilder.group({
      centre_id: [null, [Validators.required]], // Training Centre
      state: [null, [Validators.required]],
    });

    if (this.isOffline) {
      centerDetailsForm.addControl(
        'organisation_id',
        this.formBuilder.control(null, [Validators.required]),
      );
    } else {
      centerDetailsForm.addControl(
        'district',
        this.formBuilder.control(null, [Validators.required]),
      );
    }

    return centerDetailsForm;
  }

  private createPasswordFormGroup(): FormGroup {
    return this.formBuilder.group({
      confirm_password: [
        null,
        [
          Validators.required,
          this.passwordMatchValidator(() => this.registrationForm.get(['password', 'password'])),
          Validators.maxLength(environment.maxCharLimit),
        ],
      ],
      password: [
        null,
        [
          Validators.required,
          this.validationService.regexValidator(this.validationService.passwordRegex, {
            [FormErrors.password]: true,
          }),
          Validators.maxLength(environment.maxCharLimit),
        ],
      ],
    });
  }

  private createPersonalDetailsFormGroup(): FormGroup {
    return this.formBuilder.group(
      {
        date_of_birth: [null, [Validators.required]],
        email: [
          null,
          [
            this.validationService.conditionalRequiredValidator(
              () =>
                this.registrationForm.get(['personalDetails', 'mobile'])?.value == null ||
                this.registrationForm.get(['personalDetails', 'mobile'])?.value === '',
            ),
            Validators.pattern(this.validationService.emailRegex),
            Validators.maxLength(environment.maxCharLimit),
          ],
        ],
        gender: [null, [Validators.required]],
        mobile: [
          null,
          [
            this.validationService.conditionalRequiredValidator(
              () =>
                this.registrationForm.get(['personalDetails', 'email'])?.value == null ||
                this.registrationForm.get(['personalDetails', 'email'])?.value === '',
            ),
            this.validationService.regexValidator(this.validationService.contactNumberRegex, {
              [FormErrors.contactNumber]: true,
            }),
          ],
        ],
        name: [
          null,
          [
            Validators.required,
            Validators.pattern(this.validationService.atleastOneCharacterRegex),
            Validators.maxLength(environment.maxCharLimit),
            this.validationService.regexValidator(this.validationService.personNameRegex, {
              [FormErrors.onlyAlphabets]: true,
            }),
          ],
        ],
      },
      { asyncValidators: [this.personalFormValidator()] },
    );
  }

  private createRegistrationFormGroup(): FormGroup {
    return this.formBuilder.group({
      centerDetails: this.createCenterDetailsFormGroup(),
      password: this.createPasswordFormGroup(),
      personalDetails: this.createPersonalDetailsFormGroup(),
      verification: this.createVerificationFormGroup(),
    });
  }

  private createVerificationFormGroup(): FormGroup {
    return this.formBuilder.group({
      otp: [null, [Validators.required, Validators.minLength(4)]],
    });
  }

  private fetchDropdowns(): void {
    this.commonService.fetchRegistrationDropdowns().subscribe((res) => {
      this.masterListsObj = res;
    });
  }

  private onValueChangesPassword(): void {
    this.registrationForm.get(['password', 'confirm_password'])?.updateValueAndValidity({
      emitEvent: false,
    });

    // Manually showing & hiding password overlay
    if (
      this.passwordOverlayEl != null &&
      this.passwordOverlayEl.nativeElement.parentElement != null
    ) {
      this.passwordOverlayEl.nativeElement.parentElement.style.display = this.registrationForm
        .get(['password', 'password'])
        ?.hasError('password')
        ? 'block'
        : 'none';
    }
  }

  private passwordMatchValidator(passwordGetter: () => any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent == null) {
        return null;
      }

      return passwordGetter().value === control.value
        ? null
        : {
            [FormErrors.passwordMatch]: true,
          };
    };
  }

  private personalFormValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const formData = {
        ...control.value,
        date_of_birth: this.helperService.formatDate(
          control.get(['date_of_birth'])?.value,
          'yyyy-MM-dd',
        ),
      };

      if (formData.mobile == null || formData.mobile === '') {
        delete formData.mobile;
      }

      if (formData.email == null || formData.email === '') {
        delete formData.email;
      }

      return this.authService.validatePersonalDetails(formData, [422]).pipe(
        map(() => null),
        catchError((err) => {
          this.validationService.addResponseErrorToForm(<FormGroup>control, err);

          return of({
            [FormErrors.responseError]: true,
          });
        }),
      );
    };
  }

  private setEventListeners(): void {
    this.registrationForm
      .get(['personalDetails', 'email'])
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const email = this.registrationForm.get(['personalDetails', 'email'])?.value;
        if (email != null && email !== '') {
          this.registrationForm.get(['personalDetails', 'mobile'])?.updateValueAndValidity({
            emitEvent: false,
          });
        }
      });

    this.registrationForm
      .get(['personalDetails', 'mobile'])
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const mobile = this.registrationForm.get(['personalDetails', 'mobile'])?.value;
        if (mobile != null && mobile !== '') {
          this.registrationForm.get(['personalDetails', 'email'])?.updateValueAndValidity({
            emitEvent: false,
          });
        }
      });

    this.registrationForm
      .get(['password', 'password'])
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(this.onValueChangesPassword.bind(this));
  }

  private startTimer(): void {
    this.resendOtpTimeLeft = 120;
    this.resendOtpInterval = setInterval(() => {
      if (this.resendOtpTimeLeft > 0) {
        this.resendOtpTimeLeft -= 1;
      } else {
        this.stopTimer();
      }
    }, 1000);
  }

  private stopTimer(): void {
    this.resendOtpTimeLeft = 0;
    clearInterval(this.resendOtpInterval);
  }
}
