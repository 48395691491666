export enum NotificationReferenceType {
  community = 2,
  goLive = 10,
  help = 4,
  home = 1,
  job = 6,
  library = 3,
  quest = 5,
  singleCommunityPost = 8,
  singleCommunityPostComment = 9,
}
