<div
  class="notification-container"
  [class.header]="isHeader"
  [class.viewed]="notification?.is_viewed === 1"
  (click)="viewNotification(notification)"
>
  <!-- Notification Category Icon -->
  <div class="icon-container" [class.header]="isHeader">
    <mat-icon>{{ notificationReferenceObj[notification.reference]?.icon }}</mat-icon>
  </div>

  <!-- Notification Content -->
  <div class="notification-content-container">
    <!-- Notification Category Name & Date -->
    <div class="notification-header" [class.header]="isHeader">
      <!-- Category Name -->
      <div class="notification-category">
        {{ notificationReferenceObj[notification.reference]?.name }}
      </div>

      <!-- Dot -->
      <div class="dot" *ngIf="!isHeader"></div>

      <!-- Date -->
      <div class="notification-date">{{ notification.created_at }}</div>
    </div>

    <!-- Content -->
    <h4 class="notification-content" [class.overflow-ellipsis-2]="isHeader">
      {{ notification.content }}
    </h4>
  </div>
</div>
