<!-- Sync Container  -->
<div class="core-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
  <!-- Language Selection -->
  <div class="language-select">
    <app-language-select></app-language-select>
  </div>

  <div class="spacer" fxHide fxShow.xs></div>

  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Welcome -->
  <h1 class="core-main-title" fxFlexOffset="0px">{{ 'activation.welcome_quest' | translate }}</h1>

  <!-- Activation Title -->
  <h2 class="core-sub-title underline">{{ 'activation.activate_centre' | translate }}</h2>

  <!-- First Screen  -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-card
      fxLayout="column"
      fxFlex.gt-xs="400px"
      fxLayoutGap="10px"
      [formGroup]="centreActivationForm"
    >
      <div fxLayout="column" fxLayoutGap="20px">
        <!-- Caption -->
        <h4 class="core-caption">{{ 'activation.enter_activation_code' | translate }}</h4>

        <!-- Activation Code -->
        <div class="p-field">
          <span class="p-float-label required">
            <input type="text" pInputText formControlName="activation_code" />
            <label>{{ 'activation.activation_code' | translate }}</label>
          </span>
          <app-validation-error
            [control]="centreActivationForm.get(['activation_code'])"
            [field]="'activation.activation_code' | translate"
          ></app-validation-error>
        </div>
      </div>

      <!-- Button Row -->
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        *ngIf="isFirstPage; else secondScreenContent"
      >
        <!-- Sync -->
        <button
          mat-flat-button
          color="accent"
          [disabled]="centreActivationForm.invalid || isActivationButtonClicked"
          (click)="onClickSubmit()"
        >
          {{ 'common.submit' | translate }}
        </button>
      </div>

      <ng-template #secondScreenContent>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="25px">
            <p-timeline [value]="timelineList">
              <!-- Icon  -->
              <ng-template pTemplate="marker" let-event>
                <span class="custom-marker">
                  <img [src]="event?.icon" *ngIf="event?.icon !== ''; else processingIcon" />
                  <ng-template #processingIcon>
                    <div class="custom-icon" fxLayout fxLayoutAlign="center">
                      {{ event.position }}
                    </div>
                  </ng-template>
                </span>
              </ng-template>

              <!-- Content  -->
              <ng-template pTemplate="content" let-event>
                {{ event.status | translate }}
              </ng-template>
            </p-timeline>
            <p class="sub-title" fxLayoutAlign="center center" *ngIf="isCentreActivated">
              {{ 'activation.training_center_activated' | translate }}
            </p>
          </div>
        </mat-card-content>
      </ng-template>
    </mat-card>
  </div>
</div>
