import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { environment } from '../../../../environments/environment';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../../../shared/models/dialog/confirmation-dialog-data.dto';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  dialogRef!: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private httpClient: HttpClient,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  confirm(dialogData?: ConfirmationDialogData, width: string = '475px'): Observable<boolean> {
    this.dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width,
      data: dialogData,
    });

    return this.dialogRef.afterClosed().pipe(take(1));
  }

  downloadFile(filePath: string): void {
    const openWindow = window.open(filePath);
    if (!openWindow || openWindow.closed || typeof openWindow.closed === 'undefined') {
      this.showMessage('error', this.translateService.instant('common.open_window'));
    }
  }

  formatDate(date: string | number | Date, format: string): string {
    return formatDate(date, format, 'en-IN');
  }

  formatSize(bytes: number, decimalPoints: number = 3): string {
    if (bytes === 0) {
      return '0 B';
    }

    const k = 1000;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(decimalPoints))} ${sizes[i]}`;
  }

  getJsonFromFile(fileName: string): Observable<any> {
    return this.httpClient.get<any>(`assets/json/${fileName}`);
  }

  isNumberKey(e: KeyboardEvent): boolean {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Enter'].includes(e.key);
  }

  showMessage(type: MessageType, message: string = '', duration?: number) {
    const snackBarConfig: MatSnackBarConfig = {
      duration: duration ?? environment.snackbar.duration[type],
      horizontalPosition: <MatSnackBarHorizontalPosition>environment.snackbar.horizontalPosition,
      panelClass: ['snack-bar-container', type],
      verticalPosition: <MatSnackBarVerticalPosition>environment.snackbar.verticalPosition,
    };
    this.matSnackBar.open(message, undefined, snackBarConfig);
  }
}

type MessageType = 'info' | 'success' | 'error';
