export enum AnalyticsEventTypes {
  communityLinkClick = 'community_link_click',
  communityPostComment = 'community_post_comment',
  communityPostLike = 'community_post_like',
  communityPostSave = 'community_post_save',
  communitySession = 'community_session',
  homepageSession = 'homepage_session',
  infoLinkClick = 'info_link_click',
  jobFilterClick = 'job_filter_click',
  jobLinkClick = 'job_link_click',
  jobMoreDetailsClick = 'job_more_details_click',
  jobPostRemove = 'job_post_remove',
  jobPostSave = 'job_post_save',
  jobPostShare = 'job_post_share',
  jobSession = 'job_session',
  jobSortbyClick = 'job_sortby_click',
  lessonOpen = 'lesson_open',
  lessonPlayerPageSession = 'lesson_playerpage_session',
  lessonSession = 'lesson_session',
  libraryLinkClick = 'library_link_click',
  moduleSession = 'module_session',
  pointLinkClick = 'point_link_click',
  profileSession = 'profile_session',
  scoreLinkClick = 'score_link_click',
  subjectSearch = 'subject_search',
  subjectSession = 'subject_session',
  userLogin = 'user_login',
  userLogout = 'user_logout',
}
