import { Injectable } from '@angular/core';

import { AnalyticsEventCategory } from '../../../shared/configs/analytics-event-category.config';
import { AnalyticsEventTypes } from '../../../shared/configs/analytics-event-types.config';
import { UserDetailsService } from '../user-details/user-details.service';

import { environment } from 'projects/lms/src/environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private userDetailsService: UserDetailsService) {}

  sendEvent(
    eventType: AnalyticsEventTypes,
    eventCategory: AnalyticsEventCategory,
    eventLabel: string,
  ): void {
    const user = this.userDetailsService.userDetails;

    gtag('event', eventType, {
      event_label: eventLabel,
      event_category: eventCategory,
      userId: user.id,
      userType: user.roles[0].name,
      userAge: user.age,
      userState: user.state,
      centreId: user.centre_id,
      centreName: user.centre,
      orgId: user.organisation_id,
      orgName: user.organisation,
      userGender: user.gender,
    });
  }

  setAnalyticsConfig(pagePath: string): void {
    const user = this.userDetailsService.userDetails;

    if (user) {
      gtag('config', environment.gaTag, {
        custom_map: {
          dimension1: 'userId',
          dimension2: 'userType',
          dimension3: 'userAge',
          dimension4: 'userState',
          dimension5: 'centreId',
          dimension6: 'centreName',
          dimension7: 'orgId',
          dimension8: 'orgName',
          dimension9: 'userGender',
        },
        page_path: pagePath,
        userId: user?.id,
        userType: user?.roles[0].name,
        userAge: user?.age,
        userState: user?.state,
        centreId: user?.centre_id,
        centreName: user?.centre,
        orgId: user?.organisation_id,
        orgName: user?.organisation,
        userGender: user?.gender,
      });
    } else {
      gtag('config', environment.gaTag, {
        custom_map: {
          dimension1: 'userId',
          dimension2: 'userType',
          dimension3: 'userAge',
          dimension4: 'userState',
          dimension5: 'centreId',
          dimension6: 'centreName',
          dimension7: 'orgId',
          dimension8: 'orgName',
          dimension9: 'userGender',
        },
        page_path: pagePath,
      });
    }
  }
}
