import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OfflineDirective } from './offline/offline.directive';
import { PermissionDirective } from './permission/permission.directive';
import { RoleDirective } from './role/role.directive';

@NgModule({
  declarations: [OfflineDirective, PermissionDirective, RoleDirective],
  imports: [CommonModule],
  exports: [OfflineDirective, PermissionDirective, RoleDirective],
})
export class DirectivesModule {}
