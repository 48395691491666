import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { UserRegistrationType } from '../../../../shared/configs/user-registration-type.config';
import { RaiseFreescoutTicketDialogComponent } from '../../raise-freescout-ticket-dialog/raise-freescout-ticket-dialog.component';

@Component({
  selector: 'app-choose-account',
  templateUrl: './choose-account.component.html',
  styleUrls: ['./choose-account.component.scss'],
})
export class ChooseAccountComponent {
  userType: typeof UserRegistrationType = UserRegistrationType;
  userTypeId: number;

  constructor(private activatedRoute: ActivatedRoute, private matDialog: MatDialog) {
    this.userTypeId = +this.activatedRoute.snapshot.queryParamMap.get('type')!;
  }

  onClickHelp(): void {
    this.matDialog.open(RaiseFreescoutTicketDialogComponent, {
      id: 'freescout',
      disableClose: true,
      width: '700px',
    });
  }
}
