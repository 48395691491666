import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Directive({
  selector: '[appOffline]',
})
export class OfflineDirective implements OnInit {
  mode!: 'show' | 'hide';

  @Input() set appOffline(mode: 'show' | 'hide') {
    this.mode = mode;
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    const isOffline = environment.offline;

    if (this.mode === 'show') {
      if (isOffline) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } else if (this.mode === 'hide') {
      if (isOffline) {
        this.viewContainer.clear();
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }
}
