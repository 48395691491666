import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { CustomError } from '../../../shared/models/api-response';
import { HelperService } from '../../services/helper/helper.service';
import { AuthService } from '../../services/http/auth/auth.service';
import { OfflineService } from '../../services/http/offline/offline.service';
import { ValidationService } from '../../services/validation/validation.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-deactivation',
  templateUrl: './deactivation.component.html',
  styleUrls: ['./deactivation.component.scss'],
})
export class DeactivationComponent implements OnDestroy {
  centreDeactivationForm: FormGroup;
  isDeactivateButtonClicked: boolean = false;
  isLoggedIn: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private offlineService: OfflineService,
    private router: Router,
    private validationService: ValidationService,
  ) {
    this.centreDeactivationForm = this.createFormGroup();
    this.isLoggedIn = authService.isLoggedIn;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickSubmit(): void {
    this.isDeactivateButtonClicked = true;
    this.offlineService
      .deactivateCentre(this.centreDeactivationForm.get('activation_code')?.value, [422])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.helperService.showMessage('success', res.message);
          this.router.navigate(['/activation'], { relativeTo: this.activatedRoute });
        },
        error: (err: CustomError) => {
          // Error
          this.isDeactivateButtonClicked = false;
          this.validationService.addResponseErrorToForm(this.centreDeactivationForm, err);
          if (err.statusCode === 422 && err.errors?.update?.length > 0) {
            this.helperService.showMessage('info', err.errors.update[0]);
          }
        },
      });
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      activation_code: [
        null,
        [Validators.required, Validators.maxLength(environment.maxCharLimit)],
      ],
    });
  }
}
