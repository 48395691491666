import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorInterceptor } from '../../core/interceptors/http-error/http-error.interceptor';
import { HttpHeaderInterceptor } from '../../core/interceptors/http-header/http-header.interceptor';
import { LoaderInterceptor } from '../../core/interceptors/loader/loader.interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
  },
];
