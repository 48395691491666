import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DynamicEnvConfigService {
  private dynamicEnvConfig: any;

  constructor(private httpClient: HttpClient) {}

  fetchDynamicEnvConfig(): Observable<any> {
    if (environment.offline) {
      // If offline fetch dynamic env config
      return this.httpClient.get<any>('./dynamic-env-config.json').pipe(
        tap((config) => {
          this.dynamicEnvConfig = config ?? {};
          this.dynamicEnvConfig.apiBaseUrl = this.dynamicEnvConfig.server_ip;
        }),
      );
    }

    return new Observable((subscriber) => {
      this.dynamicEnvConfig = {
        apiBaseUrl: environment.apiBaseUrl,
      };

      subscriber.complete();
    });
  }

  getDynamicEnvConfig(): any {
    return this.dynamicEnvConfig;
  }
}
