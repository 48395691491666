<!--  Container -->
<div class="core-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Card -->
  <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="center">
    <mat-card
      fxFlex.gt-xs="425px"
      fxFlex.xs="60"
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="15px"
    >
      <!-- Heading -->
      <h2>{{ message | translate }}</h2>

      <!-- Buttons -->
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
      >
        <!-- Check For Update -->
        <button
          class="button"
          fxFlex.gt-xs="50"
          mat-raised-button
          color="accent"
          (click)="onClickCheckForUpdate()"
          *ngIf="!isLoadingCheckForUpdate; else spinner"
        >
          {{ 'update.check_update' | translate }}
        </button>

        <!-- Update -->
        <button
          class="button"
          fxFlex.gt-xs="50"
          mat-raised-button
          color="primary"
          [disabled]="isClickedCheckForUpdateButton"
          (click)="onClickUpdate()"
          *ngIf="!isLoadingUpdate; else spinner"
        >
          {{ 'update.update' | translate }}
        </button>

        <!-- Spinner -->
        <ng-template #spinner>
          <div class="spinner-div" fxLayoutAlign="center">
            <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
          </div>
        </ng-template>
      </div>
    </mat-card>
  </div>

  <!-- Redirect Links -->
  <div class="link-text" fxFlexOffset="25px" fxLayout="row" fxLayoutAlign="center center">
    <span routerLink="/quest/home" *ngIf="isLoggedIn; else goToLogin">
      {{ 'update.back_to_home' | translate }}
    </span>

    <ng-template #goToLogin>
      <span routerLink="/login">
        {{ 'update.back_to_login' | translate }}
      </span>
    </ng-template>
  </div>
</div>
