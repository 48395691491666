import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ACCESS_TOKEN_NUMBER } from '../../../shared/configs/http-context.config';
import { DynamicEnvConfigService } from '../../services/dynamic-env-config/dynamic-env-config.service';
import { AuthService } from '../../services/http/auth/auth.service';
import { LanguageService } from '../../services/language/language.service';

import { Observable } from 'rxjs';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private dynamicEnvConfigService: DynamicEnvConfigService,
    private languageService: LanguageService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const apiBaseUrl = environment.offline
      ? this.dynamicEnvConfigService.getDynamicEnvConfig()?.apiBaseUrl
      : environment.apiBaseUrl;
    const isApiUrl = request.url.startsWith(apiBaseUrl);

    return next.handle(isApiUrl ? this.addHeaders(request) : request);
  }

  addHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let headers: any = {
      'X-localization': this.languageService.getLanguage(),
    };

    if (this.authService.isLoggedIn) {
      // Adding Auth Token
      headers = {
        ...headers,
        Authorization: `Bearer ${this.authService.getAccessToken(
          request.context.get(ACCESS_TOKEN_NUMBER) ?? 1,
        )}`,
      };
    }

    return request.clone({
      setHeaders: headers,
    });
  }
}
