import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { LanguageService } from '../../../core/services/language/language.service';
import { Languages } from '../../configs/languages.config';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent {
  languages: typeof Languages = Languages;
  selectedLanguage: string;

  constructor(private languageService: LanguageService) {
    this.selectedLanguage = this.languageService.getLanguage();
  }

  onChangeLanguage(e: MatSelectChange): void {
    this.languageService.setLanguage(e.value);
  }
}
