<!-- Close Button -->
<button class="close-icon" tabindex="-1" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<div mat-dialog-title>&nbsp;</div>

<!-- Content -->
<mat-dialog-content>
  <!-- iframe-->
  <div class="iframe-container">
    <iframe id="lessonFrame" frameborder="0" scrolling="no" [src]="sanitizedFreescoutURL"></iframe>
  </div>
</mat-dialog-content>
