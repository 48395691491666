import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { FormErrors } from '../../../shared/configs/form-errors.config';
import { CustomError } from '../../../shared/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  readonly atleastOneCharacterRegex = /\S+/;
  readonly contactNumberRegex = /^\d{10}$/;
  readonly emailMobileRegex = /^(\d{10}|\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3}))$/;
  readonly emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
  readonly numberRegex = /^\d+$/;
  readonly passwordRegex = /.{6}/;
  readonly personNameRegex = /^[a-zA-Z _.'-]*$/;
  readonly urlRegex = /^((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)$/;

  addResponseErrorToForm(form: FormGroup, error: CustomError): void {
    // Add errors returned from BE to form

    if (error.statusCode === 422) {
      // Unprocessable Entity Error
      Object.entries(error.errors).forEach(([key, value]) => {
        form.get(key)?.setErrors({
          [FormErrors.responseError]: value[0],
        });
        form.get(key)?.markAsDirty();
      });
    }
  }

  conditionalRequiredValidator(condition: () => any): ValidatorFn {
    // Add required based on any other condition
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent == null) {
        return null;
      }

      return condition != null && condition() === true ? Validators.required(control) : null;
    };
  }

  getMessage(errorName: string, errorValue: any, fieldName: string): string {
    const errorMessages: Record<string, string> = {
      [FormErrors.contactNumber]: `${fieldName} should contain 10 digits`,
      [FormErrors.maxLength]: `Maximum ${errorValue.requiredLength} characters allowed`,
      [FormErrors.onlyAlphabets]: `${fieldName} field is invalid`,
      [FormErrors.password]: ``,
      [FormErrors.passwordMatch]: `Both passwords must match`,
      [FormErrors.pattern]: `${fieldName} format is invalid`,
      [FormErrors.required]: `${fieldName} is required`,
      [FormErrors.responseError]: `${errorValue}`,
      [FormErrors.sameEmail]: `Both emails/mobiles cannot be same`,
    };

    return errorMessages[errorName];
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    // Show custom error for pattern validation
    return (control: AbstractControl): ValidationErrors | null =>
      control.value != null && control.value !== '' && !regex.test(control.value) ? error : null;
  }
}
