import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { AnalyticsEventCategory } from '../../../shared/configs/analytics-event-category.config';
import { AnalyticsEventTypes } from '../../../shared/configs/analytics-event-types.config';
import { FormErrors } from '../../../shared/configs/form-errors.config';
import { CustomError } from '../../../shared/models/api-response';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { HelperService } from '../../services/helper/helper.service';
import { AuthService } from '../../services/http/auth/auth.service';
import { PushNotificationService } from '../../services/push-notification/push-notification.service';
import { ValidationService } from '../../services/validation/validation.service';
import { RaiseFreescoutTicketDialogComponent } from '../raise-freescout-ticket-dialog/raise-freescout-ticket-dialog.component';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  dualLoginForm: FormGroup;
  isLoading: boolean = false;
  isLogin: boolean = true;
  isLoginWithOtp: boolean = false;
  isOffline: boolean;
  isRememberPassword: boolean = false;
  isVerifyOtp: boolean = false;
  loginWithOtpForm: FormGroup;
  resendOtpTimeLeft: number = 0;
  singleLoginForm: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();
  private resendOtpInterval: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private helperService: HelperService,
    private matDialog: MatDialog,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private validationService: ValidationService,
  ) {
    this.isOffline = environment.offline;
    this.dualLoginForm = this.createDualLoginFormGroup();
    this.singleLoginForm = this.createSingleLoginFormGroup();
    this.loginWithOtpForm = this.createLoginWithOtpFormGroup();
  }

  ngOnInit(): void {
    this.setEventListeners();

    if (environment.offline) {
      this.checkCentreActivated();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickForgotPassword(): void {
    this.router.navigate(['/forgot-password']);
  }

  onClickHelp(): void {
    this.matDialog.open(RaiseFreescoutTicketDialogComponent, {
      id: 'freescout',
      disableClose: true,
      width: '700px',
    });
  }

  onClickLogin(): void {
    this.isLogin = true;
    this.isLoginWithOtp = false;
    this.isVerifyOtp = false;
    this.isRememberPassword = false;
  }

  onClickLoginWithOtp(): void {
    this.isLogin = false;
    this.isLoginWithOtp = true;
    this.isVerifyOtp = false;
    this.isRememberPassword = true;
  }

  onClickResendOtp(): void {
    this.startTimer();
    this.fetchOtp();
  }

  onClickSendOtp(): void {
    this.isLoading = true;
    this.fetchOtp();
  }

  onClickVerifyOTP(): void {
    this.verifyOtp();
  }

  onSubmitDual(): void {
    if (this.dualLoginForm.invalid) {
      return;
    }

    const formData = {
      device: 'browser',
      password_1: this.dualLoginForm.get(['learner1', 'password'])?.value,
      password_2: this.dualLoginForm.get(['learner2', 'password'])?.value,
      username_1: this.dualLoginForm.get(['learner1', 'email'])?.value,
      username_2: this.dualLoginForm.get(['learner2', 'email'])?.value,
    };

    this.authService
      .dualLogin(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.helperService.showMessage('success', res.message);
        this.navigateToNextPage();
        this.getFcmTokenFromFirebase();
        this.sendGoogleAnalyticsEvent();
      });
  }

  onSubmitSingle(): void {
    if (this.singleLoginForm.invalid) {
      return;
    }

    const formData = {
      device: 'browser',
      password: this.singleLoginForm.get(['password'])?.value,
      username: this.singleLoginForm.get(['email'])?.value,
    };

    this.authService
      .singleLogin(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.helperService.showMessage('success', res.message);
        this.navigateToNextPage(res.data.is_super_facilitator);
        this.getFcmTokenFromFirebase();
        this.sendGoogleAnalyticsEvent();
      });
  }

  private checkCentreActivated(): void {
    this.authService
      .checkCentreActivated()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res.status === 0) {
            this.router.navigate(['/activation']);
          }
        },
      });
  }

  private dualLoginSameEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent == null) {
        return null;
      }

      const email1 = this.dualLoginForm.get(['learner1', 'email'])?.value;
      const email2 = this.dualLoginForm.get(['learner2', 'email'])?.value;
      if (email1 !== email2) {
        return null;
      }

      return {
        [FormErrors.sameEmail]: true,
      };
    };
  }

  private createDualLoginFormGroup(): FormGroup {
    return this.formBuilder.group({
      learner1: this.formBuilder.group({
        email: [null, [Validators.required]],
        password: [null, [Validators.required]],
      }),
      learner2: this.formBuilder.group({
        email: [null, [Validators.required, this.dualLoginSameEmailValidator()]],
        password: [null, [Validators.required]],
      }),
    });
  }

  private createSingleLoginFormGroup(): FormGroup {
    return this.formBuilder.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  private createLoginWithOtpFormGroup(): FormGroup {
    return this.formBuilder.group({
      otp: [
        null,
        [
          this.validationService.conditionalRequiredValidator(() => this.isLoginWithOtp === false),
          Validators.minLength(4),
        ],
      ],
      username: [
        null,
        [Validators.required, Validators.pattern(this.validationService.emailMobileRegex)],
      ],
    });
  }

  private fetchOtp(): void {
    const username = this.loginWithOtpForm.get('username')?.value;

    const formData = {
      username,
    };
    this.authService
      .fetchLoginOtp(formData, [422])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.helperService.showMessage('success', res.message);
          this.isVerifyOtp = true;
          this.isLoginWithOtp = false;
          this.isRememberPassword = true;
        },
        error: (err: CustomError) => {
          // Error
          this.isLoading = false;
          this.helperService.showMessage('error', err.errors.message[0]);
          this.validationService.addResponseErrorToForm(this.loginWithOtpForm, err);
        },
      });
  }

  private getFcmTokenFromFirebase(): void {
    // Get firebase FCM token
    this.pushNotificationService.getFcmTokenFromFirebase().subscribe();
  }

  private navigateToNextPage(isSuperFacilitator: number = 0): void {
    const { redirectUrl } = this.activatedRoute?.snapshot?.queryParams;
    if (redirectUrl != null) {
      // If redirect url is present
      const urlObj = new URL(window.location.origin + redirectUrl);
      const queryParams: any = {};
      urlObj.searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      // Setting additional redirect flag to target url
      queryParams.isRedirect = true;
      this.router.navigate([urlObj.pathname], {
        queryParams,
      });
    } else {
      // If no redirect url is present
      this.router.navigateByUrl(isSuperFacilitator === 1 ? '/switch-centre' : '/quest');
    }
  }

  private sendGoogleAnalyticsEvent(): void {
    // Send event to Google Analytics
    this.googleAnalyticsService.sendEvent(
      AnalyticsEventTypes.userLogin,
      AnalyticsEventCategory.login,
      'User login successful',
    );
  }

  private setEventListeners(): void {
    this.dualLoginForm
      .get(['learner1', 'email'])
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const email = this.dualLoginForm.get(['learner1', 'email'])?.value;
        if (email != null && email !== '') {
          this.dualLoginForm.get(['learner2', 'email'])?.updateValueAndValidity({
            emitEvent: false,
          });
        }
      });

    this.dualLoginForm
      .get(['learner2', 'email'])
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const email = this.dualLoginForm.get(['learner2', 'email'])?.value;
        if (email != null && email !== '') {
          this.dualLoginForm.get(['learner1', 'email'])?.updateValueAndValidity({
            emitEvent: false,
          });
        }
      });
  }

  private startTimer(): void {
    this.resendOtpTimeLeft = 120;
    this.resendOtpInterval = setInterval(() => {
      if (this.resendOtpTimeLeft > 0) {
        this.resendOtpTimeLeft -= 1;
      } else {
        this.stopTimer();
      }
    }, 1000);
  }

  private stopTimer(): void {
    this.resendOtpTimeLeft = 0;
    clearInterval(this.resendOtpInterval);
  }

  private verifyOtp(): void {
    const formData = {
      ...this.loginWithOtpForm.value,
      device: 'browser',
    };

    this.authService
      .verifyLoginOtp(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.helperService.showMessage('success', res.message);
          this.navigateToNextPage(res.data.is_super_facilitator);
          this.isVerifyOtp = false;
        },
        error: (err: CustomError) => {
          // Error
          this.helperService.showMessage('error', err.errors.message[0]);
          this.validationService.addResponseErrorToForm(this.loginWithOtpForm, err);
        },
      });
  }
}
