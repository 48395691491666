import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HANDLED_ERRORS } from '../../../../shared/configs/http-context.config';
import { ApiResponse } from '../../../../shared/models/api-response';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineService extends ApiService {
  activateCentre(activation_code: string): Observable<ApiResponse<any>> {
    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/activate`,
      {
        activation_code,
      },
    );
  }

  checkUpdate(): Observable<ApiResponse<any>> {
    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/check-update`,
    );
  }

  deactivateCentre(
    activation_code: string,
    handledErrors: number[] = [],
  ): Observable<ApiResponse<any>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/de-activate`,
      {
        activation_code,
      },
      {
        context,
      },
    );
  }

  downloadAssets(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/get-assets`,
    );
  }

  downloadCentreCommunities(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/get-communities`,
    );
  }

  downloadCentreNotifications(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/get-notifications`,
    );
  }

  downloadCentreUsers(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/get-users`,
    );
  }

  downloadCentreVacancies(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/get-vacancies`,
    );
  }

  downloadUserActivities(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/get-user-activities`,
    );
  }

  setOfflineLog(
    position: number,
    type: number,
    handledErrors: number[] = [],
  ): Observable<ApiResponse<any>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/log`,
      {
        position,
        type,
      },
      {
        context,
      },
    );
  }

  syncCentre(): Observable<ApiResponse<any>> {
    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/sync/centre`,
    );
  }

  update(repoList: string[]): Observable<ApiResponse<any>> {
    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/update`,
      {
        keys: repoList,
      },
    );
  }

  upSyncCommunity(): Observable<ApiResponse<any>> {
    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/up-sync/user-community`,
    );
  }

  upSyncUserActivities(): Observable<ApiResponse<any>> {
    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.offlineService
      }/public/api/v1/up-sync/user-activities`,
    );
  }

  upSyncUsers(): Observable<ApiResponse<any>> {
    return this.post<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/up-sync/users`,
    );
  }
}
