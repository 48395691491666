import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from '../../../core/services/http/notification/notification.service';
import { NotificationReferenceType } from '../../configs/notification-reference-type.config';
import { Notification } from '../../models/notification/notification.model';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnDestroy {
  @Input() isHeader: boolean = false;
  @Input() notification!: Notification;
  @Input() notificationReferenceObj!: Record<number, any>;

  @Output() notificationViewed: EventEmitter<void> = new EventEmitter<void>();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private notificationService: NotificationService, private router: Router) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  viewNotification(notification: Notification): void {
    if (notification.is_viewed === 1) {
      // If already read
      this.navigateToPageFromNotification(notification);
      this.notificationViewed.emit();

      return;
    }

    this.notificationService
      .updateNotificationViewStatus(notification.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        notification.is_viewed = 1;
        this.notificationService.updateNotificationCount(-1);
        this.navigateToPageFromNotification(notification);
        this.notificationViewed.emit();
      });
  }

  private navigateToPageFromNotification(notification: Notification): void {
    if (
      [
        NotificationReferenceType.community,
        NotificationReferenceType.singleCommunityPost,
        NotificationReferenceType.singleCommunityPostComment,
      ].includes(notification.reference)
    ) {
      this.router.navigate([
        this.notificationReferenceObj[notification.reference].url,
        notification.reference,
        notification.reference_id,
      ]);
    } else {
      this.router.navigate([this.notificationReferenceObj[notification.reference]?.url]);
    }
  }
}
