export const globalEnvironment = {
  apiUrls: {
    commonService: 'common-service',
    communityService: 'community-service',
    jobService: 'job-service',
    lmsService: 'lms-service',
    notificationService: 'notification-service',
    offlineService: 'offline-service-offline',
    userService: 'user-service',
  },
  fileUploadSizeLimit: 10240000,
  maxCharLimit: 125,
  messages: {
    error: 'Oops! Something went wrong',
    sessionTimeOut: 'Session Expired! Please login again',
  },
  pageOptions: {
    pageSize: 15,
    pageSizeOptions: [15, 30, 60, 90, 120],
    showFirstLastButtons: true,
  },
  snackbar: {
    duration: {
      info: 1500,
      success: 2250,
      error: 3000,
    },
    horizontalPosition: 'end',
    verticalPosition: 'top',
  },
};
