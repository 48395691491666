import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessDeniedComponent } from './core/components/access-denied/access-denied.component';
import { ActivationComponent } from './core/components/activation/activation.component';
import { DeactivationComponent } from './core/components/deactivation/deactivation.component';
import { ErrorComponent } from './core/components/error/error.component';
import { ForgotPasswordComponent } from './core/components/forgot-password/forgot-password.component';
import { LoginComponent } from './core/components/login/login.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AccountDetailsComponent } from './core/components/registration/account-details/account-details.component';
import { ChooseAccountComponent } from './core/components/registration/choose-account/choose-account.component';
import { SwitchSuperFacilitatorCentreComponent } from './core/components/switch-super-facilitator-center/switch-super-facilitator-center.component';
import { UpdateComponent } from './core/components/update/update.component';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { CentreSelectionGuard } from './core/guards/centre-selection/centre-selection.guard';
import { LoginGuard } from './core/guards/login/login.guard';
import { OfflineGuard } from './core/guards/offline/offline.guard';

const routes: Routes = [
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'activation',
    component: ActivationComponent,
    canActivate: [OfflineGuard],
    data: { hasOfflineAccess: true },
  },
  {
    path: 'check-for-update',
    component: UpdateComponent,
    canActivate: [OfflineGuard],
    data: { hasOfflineAccess: true },
  },
  {
    path: 'deactivate-centre',
    component: DeactivationComponent,
    canActivate: [OfflineGuard],
    data: { hasOfflineAccess: true },
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'quest',
    canActivate: [AuthGuard, CentreSelectionGuard],
    loadChildren: () => import('./lazy-load/lazy-load.module').then((m) => m.LazyLoadModule),
  },
  {
    path: 'register',
    canActivate: [LoginGuard],
    children: [
      {
        path: 'choose_account',
        component: ChooseAccountComponent,
      },
      {
        path: 'account_details/:type',
        component: AccountDetailsComponent,
      },
      {
        path: '',
        redirectTo: 'choose_account',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'switch-centre',
    component: SwitchSuperFacilitatorCentreComponent,
  },
  {
    path: '',
    redirectTo: 'quest',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
