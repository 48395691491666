import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationDialogData } from '../../../../../../admin/src/app/shared/models/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  confirmationData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: ConfirmationDialogData,
    public dialogRef: MatDialogRef<ConfirmationDialogData>,
  ) {
    this.confirmationData = {
      ...this.dialogData,
    };
  }
}
