import { Component, OnDestroy } from '@angular/core';

import { CustomError } from '../../../shared/models/api-response';
import { HelperService } from '../../services/helper/helper.service';
import { AuthService } from '../../services/http/auth/auth.service';
import { OfflineService } from '../../services/http/offline/offline.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class UpdateComponent implements OnDestroy {
  isClickedCheckForUpdateButton: boolean = true;
  isLoadingCheckForUpdate: boolean = false;
  isLoadingUpdate: boolean = false;
  isLoggedIn: boolean;
  message: string;
  repoList: string[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private offlineService: OfflineService,
  ) {
    this.message = 'update.check_for_updates';
    this.isLoggedIn = this.authService.isLoggedIn;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickCheckForUpdate(): void {
    this.isLoadingCheckForUpdate = true;
    this.offlineService
      .checkUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.isLoadingCheckForUpdate = false;
          if (res.total !== 0 || res.updates_for.length > 0) {
            this.message = 'update.updates_are_available';
            this.isClickedCheckForUpdateButton = false;
            this.repoList = res.updates_for;
          } else if (res.total === 0 || res.updates_for.length === 0) {
            this.message = 'update.you_are_upto_date';
          }
        },
        error: (err: CustomError) => {
          // Error
          this.isLoadingCheckForUpdate = false;
          if (err.statusCode === 422 && err.errors.update?.length !== 0) {
            this.helperService.showMessage('info', err.errors.update[0]);
          }
        },
      });
  }

  onClickUpdate(): void {
    this.isLoadingUpdate = true;
    this.offlineService
      .update(this.repoList)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.isLoadingUpdate = false;
          this.message = 'update.you_are_upto_date';
          this.isClickedCheckForUpdateButton = true;
          this.helperService.showMessage('success', res.message);
        },
        error: (err: CustomError) => {
          // Error
          this.isLoadingUpdate = false;
          if (err.statusCode === 422 && err.errors.update?.length !== 0) {
            this.helperService.showMessage('info', err.errors.update[0]);
          }
        },
      });
  }
}
