import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HANDLED_ERRORS } from '../../../../shared/configs/http-context.config';
import { ApiResponse } from '../../../../shared/models/api-response';
import { Center } from '../../../../shared/models/content/center.model';
import { MasterListsObj } from '../../../../shared/models/master-list';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService extends ApiService {
  updateFacilitatorCenter(
    center: Center,
    handledErrors: number[] = [],
  ): Observable<ApiResponse<Center>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<Center>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.userService
      }/public/api/v1/super-facilitator/update-centre`,
      center,
      {
        context,
      },
    );
  }

  fetchCenterLists(): Observable<MasterListsObj> {
    return this.get<MasterListsObj>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.userService
      }/public/api/v1/super-facilitator/list-centres`,
    );
  }
}
