import { Injectable } from '@angular/core';

import { NotificationReferenceType } from '../../../../shared/configs/notification-reference-type.config';
import { ApiResponse } from '../../../../shared/models/api-response';
import { MasterListItem } from '../../../../shared/models/master-list';
import { Notification } from '../../../../shared/models/notification/notification.model';
import { ApiService } from '../api.service';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends ApiService {
  private notificationCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  fetchNotificationCount(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.notificationService
      }/public/api/v1/notification-count`,
    );
  }

  fetchNotificationList(
    page: number,
    limit: number,
    filters: Record<string, any>,
  ): Observable<ApiResponse<Notification[]>> {
    return this.get<ApiResponse<Notification[]>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.notificationService}/public/api/v1/notification`,
      {
        params: this.getQueryStringParameters({
          page,
          limit,
          ...this.convertToFilterParams(filters),
        }),
      },
    );
  }

  fetchNotificationWithoutFilterList(
    page: number,
    limit: number,
  ): Observable<ApiResponse<Notification[]>> {
    return this.get<ApiResponse<Notification[]>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.notificationService}/public/api/v1/notification`,
      {
        params: this.getQueryStringParameters({
          page,
          limit,
        }),
      },
    );
  }

  getNotificationCount$(): Observable<number> {
    return this.notificationCount$.asObservable();
  }

  getNotificationReferenceObj(
    notificationList: MasterListItem[],
    isLearner: boolean,
  ): Record<number, any> {
    return notificationList.reduce(
      (notificationReferenceObj: Record<number, any>, notificationReference) => {
        // set url & icon to each notification type
        const item: any = {
          ...notificationReference,
        };
        switch (notificationReference.id) {
          case NotificationReferenceType.home: // Home
            item.url = '/quest/home';
            item.icon = 'home';
            break;

          case NotificationReferenceType.library: // Library
            item.url = '/quest/library';
            item.icon = 'menu_book';
            break;

          case NotificationReferenceType.help: // Help
            item.url = '/quest/help';
            item.icon = 'quiz';
            break;

          case NotificationReferenceType.job: // Job
            item.url = '/quest/jobs';
            item.icon = 'card_travel';
            break;

          case NotificationReferenceType.community: // Community
          // break omitted

          case NotificationReferenceType.singleCommunityPost: // Single Community Post
          // break omitted

          case NotificationReferenceType.singleCommunityPostComment: // Single Community Post Comment
            item.url = `/quest/community/notification-tag`;
            item.icon = 'chat';
            break;

          case NotificationReferenceType.goLive: // Go live
            item.url = '/quest/meetings';
            item.icon = 'videocam';
            break;

          case NotificationReferenceType.quest: // Quest
          // break omitted

          default:
            item.url = `/quest/profile/${isLearner ? 'learner' : 'facilitator'}`;
            item.icon = 'person';
            break;
        }
        notificationReferenceObj[<number>notificationReference.id] = item;

        return notificationReferenceObj;
      },
      {},
    );
  }

  setNotificationCount$(count: number) {
    this.notificationCount$.next(count);
  }

  updateNotificationCount(count: number): void {
    const currentCount = this.notificationCount$.getValue();
    this.notificationCount$.next(currentCount + count);
  }

  updateNotificationViewStatus(notificationId?: string): Observable<ApiResponse<any>> {
    return this.put<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.commonService}/public/api/v1/notification`,
      {
        notification_id: notificationId,
        is_viewed: 1,
      },
    );
  }
}
