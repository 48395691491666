import { NgModule } from '@angular/core';

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  exports: [
    AccordionModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    CheckboxModule,
    DropdownModule,
    DropdownModule,
    FileUploadModule,
    FileUploadModule,
    GalleriaModule,
    InputMaskModule,
    InputMaskModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MultiSelectModule,
    MultiSelectModule,
    MultiSelectModule,
    OverlayPanelModule,
    OverlayPanelModule,
    OverlayPanelModule,
    PasswordModule,
    ProgressBarModule,
    RadioButtonModule,
    RadioButtonModule,
    RatingModule,
    SkeletonModule,
    SplitButtonModule,
    TableModule,
    TimelineModule,
    ToggleButtonModule,
    TooltipModule,
  ],
})
export class PrimeNgModule {}
