import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { HANDLED_ERRORS } from '../../../shared/configs/http-context.config';
import { CustomError } from '../../../shared/models/api-response';
import { HelperService } from '../../services/helper/helper.service';
import { AuthService } from '../../services/http/auth/auth.service';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog,
    private helperService: HelperService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((err) => this.handleError(request, err)));
  }

  private handleError(request: HttpRequest<unknown>, httpError: HttpErrorResponse) {
    const customError: CustomError = {
      statusCode: httpError.status,
      statusText: httpError.statusText,
      message: httpError.error?.message,
      errors: httpError.error?.errors,
      originalError: httpError,
    };

    // Logout if 401 Unauthorized
    if (customError.statusCode === 401) {
      this.dialog.closeAll();
      this.authService.logoutLocally();
      this.router.navigate(['/login'], {
        queryParams: {
          redirectUrl: this.router.url,
        },
      });
    }

    const isRedirect = this.activatedRoute.snapshot.queryParamMap.get('isRedirect') != null;

    // If 403 Forbidden response returned from api navigate to access denied if not due to redirect
    if (customError.statusCode === 403) {
      this.dialog.closeAll();
      this.router.navigate([isRedirect ? '/quest' : '/access-denied']);
    }

    // Show message if error is not handled in component and if not redirected from login
    if (!request.context.get(HANDLED_ERRORS).includes(customError.statusCode) && !isRedirect) {
      let message: string;
      switch (customError.statusCode) {
        case 401:
          message = environment.messages.sessionTimeOut;
          break;

        case 500:
          message = environment.messages.error;
          break;

        default:
          message = customError.message ?? environment.messages.error;
          break;
      }
      this.helperService.showMessage('error', message);
    }

    return throwError(customError);
  }
}
