export const Languages = {
  english: {
    code: 'en',
    label: 'English',
  },
  gujarati: {
    code: 'gu',
    label: 'ગુજરાતી',
  },
  hindi: {
    code: 'hi',
    label: 'हिंदी',
  },
  kannada: {
    code: 'kn',
    label: 'ಕನ್ನಡ',
  },
  malayalam: {
    code: 'ml',
    label: 'മലയാളം',
  },
  marathi: {
    code: 'mr',
    label: 'मराठी',
  },
  tamil: {
    code: 'ta',
    label: 'தமிழ்',
  },
};
