import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomError } from '../../../shared/models/api-response';
import { MasterListsObj } from '../../../shared/models/master-list';
import { UserDetails } from '../../../shared/models/user-details.dto';
import { ContentService } from '../../services/http/content/content.service';
import { UserDetailsService } from '../../services/user-details/user-details.service';
import { ValidationService } from '../../services/validation/validation.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-switch-super-facilitator-center',
  templateUrl: './switch-super-facilitator-center.component.html',
  styleUrls: ['./switch-super-facilitator-center.component.scss'],
})
export class SwitchSuperFacilitatorCentreComponent implements OnInit, OnDestroy {
  centerTypeList: MasterListsObj = {};
  superFacilitatorForm!: FormGroup;
  userDetails: UserDetails;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userDetailsService: UserDetailsService,
    private validationService: ValidationService,
  ) {
    this.superFacilitatorForm = this.createFormGroup();
    this.userDetails = this.userDetailsService.userDetails;
  }

  ngOnInit(): void {
    this.fetchCenterLists();
    this.setSuperFacilitatorForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmitCenter(): void {
    this.contentService
      .updateFacilitatorCenter(this.superFacilitatorForm.value, [422])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.userDetailsService.setUserDetails({
            ...this.userDetails,
            centre_id: res.data.centre_id,
            isCentreSelected: true,
          });
          const url = this.activatedRoute.snapshot.queryParams.redirectUrl ?? '/quest';
          this.router.navigate([url]);
        },
        error: (err: CustomError) => {
          // Error
          this.validationService.addResponseErrorToForm(this.superFacilitatorForm, err);
        },
      });
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      centre_id: [null, [Validators.required]],
    });
  }

  private fetchCenterLists() {
    this.contentService.fetchCenterLists().subscribe((res) => {
      this.centerTypeList = res;
    });
  }

  private setSuperFacilitatorForm(): void {
    this.superFacilitatorForm.patchValue({
      centre_id: this.userDetails.centre_id,
    });
  }
}
