import { Injectable } from '@angular/core';

import { UserDetailsService } from '../user-details/user-details.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private userDetailsService: UserDetailsService) {}

  isUserAllowed(requiredListsObj: { roleList?: any[]; permissionList?: any[] }): boolean {
    // Check if user has role

    const isRoleAllowed =
      requiredListsObj.roleList == null
        ? true
        : this.userDetailsService.userDetails.roles.some((userRoles) =>
            requiredListsObj.roleList!.includes(userRoles.name),
          );

    // Check if user has permission
    const isPermissionAllowed =
      requiredListsObj.permissionList == null
        ? true
        : this.userDetailsService.userDetails.permissions.some((userPermission) =>
            requiredListsObj.permissionList!.includes(userPermission.name),
          );

    return isRoleAllowed && isPermissionAllowed;
  }
}
