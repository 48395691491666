import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

export const materialProviders = [
  {
    provide: ErrorStateMatcher,
    useClass: ShowOnDirtyErrorStateMatcher,
  },

  {
    provide: MAT_DATE_LOCALE,
    useValue: 'en-IN',
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '95vw',
      restoreFocus: false,
    },
  },
  {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false },
  },
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      position: 'above',
    },
  },
];
