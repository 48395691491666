<div class="error-page-container">
  <mat-card>
    <mat-card-content>
      <img src="assets/images/error-images/401.png" />

      <div class="content-container">
        <div>
          <h1 class="title">{{ 'access_denied.title' | translate }}</h1>
          <h3 class="sub-title">{{ 'access_denied.sub_title' | translate }}</h3>
        </div>

        <button mat-flat-button color="accent" routerLink="/">
          {{ 'common.go_to_home_page' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
