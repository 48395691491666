<!-- Close Button -->
<button class="close-icon" tabindex="-1" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<div
  class="confirmation-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
>
  <!-- Delete Icon -->
  <div class="button-style" fxLayoutAlign="center">
    <img class="tick-icon" src="assets/images/home/delete_icon.svg" />
  </div>

  <!-- Content -->
  <mat-dialog-content fxLayout="row" fxLayoutAlign="center">
    <span>{{ confirmationData.message ?? 'common.confirmation' | translate }}</span>
  </mat-dialog-content>

  <!-- Confirm Button -->
  <button mat-flat-button class="confirm-button" [mat-dialog-close]="true">
    {{ confirmationData.confirmButtonText ?? 'common.delete_yes' | translate | titlecase }}
  </button>

  <!-- Cancel Button -->
  <button mat-flat-button class="cancel-button" color="primary" [mat-dialog-close]="false">
    {{ confirmationData.cancelButtonText ?? 'common.delete_no' | translate | titlecase }}
  </button>
</div>
