import { Injectable } from '@angular/core';

import { UserRoles } from '../../../../../../admin/src/app/shared/configs/user-roles.config';
import { StorageTokens } from '../../../shared/configs/storage-tokens.config';
import { UserDetails } from '../../../shared/models/user-details.dto';
import { StorageService } from '../storage/storage.service';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  // To get primary user details
  get userDetails(): UserDetails {
    return this.getUserDetailsValue();
  }

  // To get primary user details observable
  get userDetails$(): Observable<UserDetails> {
    return this.userDetailsSubject$.asObservable();
  }

  private userDetailsSubject$: BehaviorSubject<UserDetails> = new BehaviorSubject<UserDetails>(
    this.storageService.getFromStorage('local', StorageTokens.userDetails1),
  );

  constructor(private storageService: StorageService) {}

  // To get any user details
  getUserDetailsValue(userNumber: number = 1): UserDetails {
    return userNumber === 1
      ? this.userDetailsSubject$.value
      : this.storageService.getFromStorage('local', StorageTokens.userDetails2);
  }

  hasRoles(roleList: string[]): boolean {
    return this.userDetails?.roles.some((role) => roleList.includes(role.name));
  }

  isAlumni(): boolean {
    return this.userDetails?.roles.some((role) => role.name === UserRoles.alumni);
  }

  isDualLogin(): boolean {
    return this.storageService.getFromStorage('local', StorageTokens.userDetails2) != null;
  }

  isFacilitator(): boolean {
    return this.userDetails?.roles.some((role) =>
      [UserRoles.facilitator, UserRoles.masterTrainer, UserRoles.superFacilitator].includes(
        role.name,
      ),
    );
  }

  isLearner(): boolean {
    return this.userDetails?.roles.some((role) =>
      [UserRoles.alumni, UserRoles.student].includes(role.name),
    );
  }

  // To set primary user details to subject
  setUserDetails(userDetails: UserDetails, userNumber: number = 1): void {
    const storageKey: string =
      userNumber === 1 ? StorageTokens.userDetails1 : StorageTokens.userDetails2;
    this.storageService.addToStorage('local', storageKey, userDetails);

    if (userNumber === 1) {
      this.userDetailsSubject$.next(userDetails);
    }
  }

  switchUser(): void {
    // Switching access token
    const accessToken1 = this.storageService.getFromStorage('local', StorageTokens.accessToken1);
    const accessToken2 = this.storageService.getFromStorage('local', StorageTokens.accessToken2);
    this.storageService.addToStorage('local', StorageTokens.accessToken1, accessToken2);
    this.storageService.addToStorage('local', StorageTokens.accessToken2, accessToken1);

    // Switching user details
    const userDetails1 = this.storageService.getFromStorage('local', StorageTokens.userDetails1);
    const userDetails2 = this.storageService.getFromStorage('local', StorageTokens.userDetails2);
    this.setUserDetails(userDetails1, 2);
    this.setUserDetails(userDetails2);
  }

  // To remove primary user details from subject
  removeUserDetails(): void {
    this.userDetailsSubject$.next(undefined as any);
  }
}
