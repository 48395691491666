<div class="core-container" fxLayout="column" fxLayoutAlign="center center">
  <!-- Language Selection -->
  <div class="language-select">
    <app-language-select></app-language-select>
  </div>

  <div class="spacer" fxHide fxShow.xs></div>

  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Welcome -->
  <h1 class="core-main-title">{{ 'login.welcome' | translate }}</h1>

  <!-- Tab Group -->
  <mat-tab-group color="accent" mat-align-tabs="center" fxFlexAlign="stretch" *ngIf="isLogin">
    <!-- Single Login -->
    <mat-tab #singleLoginTab [label]="'login.single_login' | translate">
      <div fxLayout="row" fxLayoutAlign="center">
        <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95">
          <mat-card-content fxLayout="row" fxLayoutAlign="center">
            <form
              fxFlex.gt-md="60"
              fxFlex.md="70"
              fxFlex.sm="80"
              fxFlex.xs="90"
              fxLayout="column"
              fxLayoutAlign="center stretch"
              fxLayoutGap="5px"
              [formGroup]="singleLoginForm"
            >
              <!-- Email ID / Mobile Number -->
              <div class="p-field">
                <span class="p-float-label">
                  <input type="text" pInputText formControlName="email" />
                  <label>{{ 'login.email_mobile' | translate }}</label>
                </span>
                <app-validation-error
                  [control]="singleLoginForm.get(['email'])"
                  [field]="'login.email_mobile' | translate"
                ></app-validation-error>
              </div>

              <!-- Password -->
              <div class="p-field">
                <span class="p-float-label">
                  <p-password
                    formControlName="password"
                    appendTo="body"
                    styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                    [toggleMask]="true"
                    [feedback]="false"
                  >
                  </p-password>
                  <label>{{ 'login.password' | translate }}</label>
                </span>
                <app-validation-error
                  [control]="singleLoginForm.get(['password'])"
                  [field]="'login.password' | translate"
                ></app-validation-error>
              </div>

              <!-- Login Button -->
              <button
                mat-flat-button
                fxFlexAlign="center"
                color="primary"
                [disabled]="singleLoginForm.invalid"
                (click)="onSubmitSingle()"
              >
                {{ 'login.login' | translate }}
              </button>
              <h3 fxLayoutAlign="center" fxFlexOffset="5px" *appOffline="'hide'">
                {{ 'common.or' | translate }}
              </h3>

              <!-- Login with OTP Button -->
              <button
                mat-flat-button
                fxFlexAlign="center"
                fxFlexOffset="5px"
                color="accent"
                (click)="onClickLoginWithOtp()"
                *appOffline="'hide'"
              >
                {{ 'login.login_with_otp' | translate }}
              </button>

              <!-- Forgot Password? -->
              <div
                class="forgot-password"
                fxLayoutAlign="center"
                (click)="onClickForgotPassword()"
                *appOffline="'hide'"
              >
                Forgot Password?
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>

    <!-- Dual Login -->
    <mat-tab [label]="'login.dual_login' | translate">
      <div fxLayout="row" fxLayoutAlign="center">
        <mat-card fxFlex.gt-sm="830px" fxFlex.sm="85" fxFlex.xs="95">
          <mat-card-content fxLayout="row" fxLayoutAlign="center">
            <form
              fxFlex.gt-md="80"
              fxFlex.md="85"
              fxFlex.lt-md="90"
              fxLayout="column"
              fxLayoutAlign="space-between stretch"
              [formGroup]="dualLoginForm"
              (ngSubmit)="onSubmitDual()"
            >
              <!-- Input Row -->
              <div
                fxLayout.gt-xs="row"
                fxLayout.xs="column"
                fxLayoutAlign.gt-xs="space-between center"
                fxLayoutAlign.xs="center stretch"
                fxLayoutGap.gt-xs="0"
                fxLayoutGap.xs="30px"
              >
                <!-- Learner 1 -->
                <div fxLayout="column" fxFlex.gt-xs="45" fxLayoutGap="5px" formGroupName="learner1">
                  <!-- Title -->
                  <h2>{{ 'login.learner_1' | translate }}</h2>

                  <!-- Email ID / Mobile Number -->
                  <div class="p-field" fxFlexOffset="15px">
                    <span class="p-float-label">
                      <input type="text" pInputText formControlName="email" />
                      <label>{{ 'login.email_mobile' | translate }}</label>
                    </span>
                    <app-validation-error
                      [control]="dualLoginForm.get(['learner1', 'email'])"
                      [field]="'login.email_mobile' | translate"
                    ></app-validation-error>
                  </div>

                  <!-- Password -->
                  <div class="p-field">
                    <span class="p-float-label">
                      <p-password
                        formControlName="password"
                        appendTo="body"
                        styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                        [toggleMask]="true"
                        [feedback]="false"
                      >
                      </p-password>
                      <label>{{ 'login.password' | translate }}</label>
                    </span>
                    <app-validation-error
                      [control]="dualLoginForm.get(['learner1', 'password'])"
                      [field]="'login.password' | translate"
                    ></app-validation-error>
                  </div>
                </div>

                <!-- Learner 2 -->
                <div fxLayout="column" fxFlex.gt-xs="45" fxLayoutGap="5px" formGroupName="learner2">
                  <!-- Title -->
                  <h2>{{ 'login.learner_2' | translate }}</h2>

                  <!-- Email ID / Mobile Number -->
                  <div class="p-field" fxFlexOffset="15px">
                    <span class="p-float-label">
                      <input type="text" pInputText formControlName="email" />
                      <label>{{ 'login.email_mobile' | translate }}</label>
                    </span>
                    <app-validation-error
                      [control]="dualLoginForm.get(['learner2', 'email'])"
                      [field]="'login.email_mobile' | translate"
                    ></app-validation-error>
                  </div>
                  <!-- Password -->
                  <div class="p-field">
                    <span class="p-float-label">
                      <p-password
                        formControlName="password"
                        appendTo="body"
                        styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                        [toggleMask]="true"
                        [feedback]="false"
                      >
                      </p-password>
                      <label>{{ 'login.password' | translate }}</label>
                    </span>
                    <app-validation-error
                      [control]="dualLoginForm.get(['learner2', 'password'])"
                      [field]="'login.password' | translate"
                    ></app-validation-error>
                  </div>
                </div>
              </div>

              <!-- Button Row -->
              <!-- Login Button -->
              <button
                mat-flat-button
                fxFlexAlign="center"
                color="primary"
                [disabled]="dualLoginForm.invalid"
              >
                {{ 'login.login' | translate }}
              </button>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>

  <!-- Login with OTP -->
  <ng-container *ngIf="isLoginWithOtp || isVerifyOtp">
    <h2 class="core-sub-title underline">{{ 'login.login_with_otp' | translate }}</h2>

    <!-- Enter Login ID -->
    <div
      fxLayout="row"
      fxLayoutAlign="center"
      fxFlexOffset="15px"
      fxFlexAlign="stretch"
      *ngIf="isLoginWithOtp"
    >
      <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95">
        <mat-card-content fxLayout="row" fxLayoutAlign="center">
          <form
            fxFlex.gt-md="60"
            fxFlex.md="70"
            fxFlex.sm="80"
            fxFlex.xs="90"
            fxLayout="column"
            fxLayoutAlign="center stretch"
            [formGroup]="loginWithOtpForm"
          >
            <!-- Heading 1 -->
            <h2 fxLayoutAlign="center" fxLayout.md="center">
              {{ 'login.your_login_id' | translate }}
            </h2>

            <!-- Email ID / Mobile Number -->
            <div class="p-field" fxFlexOffset="30px">
              <span class="p-float-label">
                <input type="text" pInputText formControlName="username" />
                <label>{{ 'login.email_or_mobile' | translate }}</label>
              </span>
              <app-validation-error
                [control]="loginWithOtpForm.get(['username'])"
                [field]="'login.email_or_mobile' | translate"
              ></app-validation-error>
            </div>
          </form>
        </mat-card-content>

        <!-- Button Row -->
        <div fxLayoutAlign="space-around">
          <!-- Send OTP -->
          <button
            mat-flat-button
            color="primary"
            (click)="onClickSendOtp()"
            [disabled]="loginWithOtpForm.invalid"
            *ngIf="!isLoading; else spinner"
          >
            {{ 'login.send_otp' | translate }}
          </button>
          <ng-template #spinner>
            <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
          </ng-template>
        </div>
      </mat-card>
    </div>

    <!-- Verify OTP-->
    <div
      fxLayout="row"
      fxLayoutAlign="center"
      fxFlexAlign="stretch"
      fxFlexOffset="15px"
      *ngIf="isVerifyOtp"
    >
      <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95">
        <mat-card-content fxLayout="column" fxLayoutAlign="center stretch">
          <form
            fxFlex.gt-md="60"
            fxFlex.md="70"
            fxFlex.sm="80"
            fxFlex.xs="90"
            fxLayout="column"
            fxLayoutAlign="center stretch"
            [formGroup]="loginWithOtpForm"
          >
            <!-- Heading 1 -->
            <h2 fxLayoutAlign="center" fxLayout.md="center">
              {{ 'login.otp_verification' | translate }}
            </h2>

            <!-- Heading 2 -->
            <div fxFlexOffset="30px" fxLayoutAlign="center" fxLayoutAlign.md="center">
              <h3 class="enter-otp">
                {{
                  'login.enter_otp'
                    | translate: { name: loginWithOtpForm.get(['username'])?.value.slice(0, 4) }
                }}
              </h3>
            </div>

            <!-- OTP -->
            <div fxLayoutAlign="center" fxFlexOffset="30px">
              <div fxFlex="85px">
                <p-inputMask
                  placeholder="X-X-X-X"
                  formControlName="otp"
                  mask="9-9-9-9"
                  slotChar="X"
                  [unmask]="true"
                ></p-inputMask>
                <app-validation-error
                  [control]="loginWithOtpForm.get(['otp'])"
                  [field]="'login.otp' | translate"
                ></app-validation-error>
              </div>
            </div>
          </form>
        </mat-card-content>

        <!-- Button Row -->
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <!-- Resend OTP -->
          <button
            mat-flat-button
            color="accent"
            (click)="onClickResendOtp()"
            *ngIf="resendOtpTimeLeft === 0; else otpTimer"
          >
            {{ 'login.resend_otp' | translate }}
          </button>

          <!-- Otp Timer -->
          <ng-template #otpTimer>
            <span class="otp-timer">
              {{
                'login.resend_otp_in'
                  | translate: { time: (resendOtpTimeLeft * 1000 | date: 'mm:ss':'UTC') }
              }}
            </span>
          </ng-template>

          <!-- Verify OTP -->
          <button
            mat-flat-button
            color="primary"
            (click)="onClickVerifyOTP()"
            [disabled]="loginWithOtpForm.invalid"
          >
            {{ 'common.next' | translate }}
          </button>
        </div>
      </mat-card>
    </div>
  </ng-container>

  <!-- New User -->
  <div
    class="link-text"
    fxFlexOffset="25px"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
    *ngIf="isLogin"
  >
    <span>
      {{ 'login.new_user' | translate }}
    </span>
    <button mat-flat-button color="accent" routerLink="/register">
      {{ 'login.register' | translate }}
    </button>
  </div>

  <!-- Remember Password -->
  <div class="link-text" fxFlexOffset="40px" *ngIf="isRememberPassword">
    {{ 'login.remember_password' | translate }}
    <button mat-flat-button color="accent" (click)="onClickLogin()">
      {{ 'login.login' | translate }}
    </button>
  </div>

  <!-- Offline Links -->
  <div
    class="link-text offline"
    fxFlexOffset="25px"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="50px"
    *ngIf="isOffline"
  >
    <span routerLink="/check-for-update">
      {{ 'login.check_for_update' | translate }}
    </span>

    <span routerLink="/deactivate-centre">
      {{ 'login.deactivate_center' | translate }}
    </span>
  </div>

  <!-- Help -->
  <div class="link-text help" fxFlexOffset="15px" (click)="onClickHelp()">
    {{ 'login.help' | translate }}
  </div>
</div>
