import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { HelperService } from '../../../core/services/helper/helper.service';

@Component({
  selector: 'app-file-upload-preview',
  templateUrl: './file-upload-preview.component.html',
  styleUrls: ['./file-upload-preview.component.scss'],
})
export class FileUploadPreviewComponent {
  @Input() isCompact: boolean = false;
  @Input() name!: string;
  @Input() size!: number;
  @Input() url!: string;

  @Output() removeFile: EventEmitter<void> = new EventEmitter<void>();

  constructor(public helperService: HelperService, public mediaObserver: MediaObserver) {}
}
