import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-raise-freescout-ticket-dialog',
  templateUrl: './raise-freescout-ticket-dialog.component.html',
  styleUrls: ['./raise-freescout-ticket-dialog.component.scss'],
})
export class RaiseFreescoutTicketDialogComponent {
  sanitizedFreescoutURL!: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) {
    this.sanitizedFreescoutURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
      environment.freeScoutUrl,
    );
  }
}
