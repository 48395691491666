<div class="core-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="spacer" fxHide fxShow.xs></div>

  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Super Facilitator -->
  <h1 class="core-main-title">{{ 'login.super-facilitator' | translate }}</h1>

  <!-- Select Center -->
  <div fxLayout="row" fxLayoutAlign="center" fxFill>
    <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95">
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <form
          fxFlex.gt-md="60"
          fxFlex.md="70"
          fxFlex.sm="80"
          fxFlex.xs="90"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          fxLayoutGap="10px"
          [formGroup]="superFacilitatorForm"
          (ngSubmit)="onSubmitCenter()"
        >
          <!-- Title -->
          <h4 fxFlexAlign="center">{{ 'login.select_center' | translate }}</h4>

          <!-- Training Center -->
          <div class="p-field">
            <span class="p-float-label">
              <p-dropdown
                formControlName="centre_id"
                optionLabel="name"
                optionValue="id"
                filterBy="name"
                filterMatchMode="startsWith"
                appendTo="body"
                [options]="centerTypeList.data"
                [autoDisplayFirst]="false"
                [filter]="true"
                [resetFilterOnHide]="true"
              ></p-dropdown>
              <label>{{ 'login.select_center' | translate }}</label>
            </span>
          </div>

          <!-- Submit Button -->
          <button
            mat-flat-button
            fxFlexAlign="center"
            color="accent"
            [disabled]="superFacilitatorForm.invalid"
          >
            {{ 'login.submit' | translate }}
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
