import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';

import { AuthService } from '../../services/http/auth/auth.service';
import { PermissionService } from '../../services/permission/permission.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserAllowed(route, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserAllowed(next, state);
  }

  private isUserAllowed(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): any {
    if (!this.authService.isLoggedIn) {
      // User is not logged in or token has expired
      // Redirect to login page with the return url
      this.authService.logoutLocally();

      return this.router.parseUrl(`/login?redirectUrl=${state.url}`);
    }

    return this.permissionService.isUserAllowed({
      roleList: activatedRouteSnapshot.data.roleList,
      permissionList: activatedRouteSnapshot.data.permissionList,
    })
      ? true
      : this.redirectUser(activatedRouteSnapshot);
  }

  private redirectUser(activatedRouteSnapshot: ActivatedRouteSnapshot): UrlTree {
    // Redirect user to home page if coming from login page
    const url = activatedRouteSnapshot.queryParamMap.get('isRedirect')
      ? '/quest'
      : '/access-denied';

    return this.router.parseUrl(url);
  }
}
