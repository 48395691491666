import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GoogleAnalyticsService } from './core/services/google-analytics/google-analytics.service';
import { LanguageService } from './core/services/language/language.service';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private languageService: LanguageService,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.languageService.setDefaultLanguage();
    this.setGoogleAnalyticsConfig();
  }

  ngOnInit(): void {
    this.setEventListeners();
  }

  setGoogleAnalyticsConfig(): void {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.googleAnalyticsService.setAnalyticsConfig(event.urlAfterRedirects);
      });
  }

  setEventListeners(): void {
    this.languageService.languageChange$.subscribe((e) => {
      let fontSize;
      switch (e.lang) {
        /* case 'gu':
          fontSize = '14px';
          break;

        case 'hi':
          fontSize = '14px';
          break;

        case 'kn':
          fontSize = '14px';
          break; */

        case 'ml':
          fontSize = '12px';
          break;

        /* case 'mr':
          fontSize = '14px';
          break; */

        case 'ta':
          fontSize = '12px';
          break;

        case 'en':
        // break omitted

        default:
          fontSize = '14px';
          break;
      }
      // Setting font size of html tag
      this.renderer.setAttribute(document.documentElement, 'lang', e.lang);
      this.renderer.setStyle(document.documentElement, 'font-size', fontSize);
    });
  }
}
