<!-- Sync Container  -->
<div class="core-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Deactivation Title -->
  <h2 class="core-sub-title underline">{{ 'deactivate.deactivate_centre' | translate }}</h2>

  <!-- Card -->
  <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="center">
    <mat-card
      fxFlex.gt-xs="425px"
      fxFlex.xs="85"
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="10px"
      [formGroup]="centreDeactivationForm"
    >
      <!-- Caption -->
      <h4 class="core-caption">{{ 'deactivate.enter_deactivation_code' | translate }}</h4>

      <!-- Deactivation Code -->
      <div class="p-field">
        <span class="p-float-label required">
          <input type="text" pInputText formControlName="activation_code" />
          <label>{{ 'deactivate.deactivation_code' | translate }}</label>
        </span>
        <app-validation-error
          [control]="centreDeactivationForm.get(['activation_code'])"
          [field]="'deactivate.deactivation_code' | translate"
        ></app-validation-error>
      </div>

      <!-- Submit Button -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <button
          mat-flat-button
          color="accent"
          [disabled]="centreDeactivationForm.invalid || isDeactivateButtonClicked"
          (click)="onClickSubmit()"
          *ngIf="!isDeactivateButtonClicked; else spinner"
        >
          {{ 'common.submit' | translate }}
        </button>

        <!-- Spinner -->
        <ng-template #spinner>
          <div class="spinner-div" fxLayoutAlign="center">
            <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
          </div>
        </ng-template>
      </div>
    </mat-card>
  </div>

  <!-- Redirect Links -->
  <div class="link-text" fxLayout="row" fxLayoutAlign="center center">
    <span routerLink="/quest/home" *ngIf="isLoggedIn; else goToLogin">
      {{ 'deactivate.back_to_home' | translate }}
    </span>

    <ng-template #goToLogin>
      <span routerLink="/login">
        {{ 'deactivate.back_to_login' | translate }}
      </span>
    </ng-template>
  </div>
</div>
