<mat-form-field class="language" color="accent">
  <mat-label>{{ 'language' | translate }}</mat-label>
  <mat-select
    [panelClass]="'language-dropdown'"
    (selectionChange)="onChangeLanguage($event)"
    [(ngModel)]="selectedLanguage"
  >
    <mat-option *ngFor="let langObj of languages | keyvalue" [value]="langObj.value.code">{{
      langObj.value.label
    }}</mat-option>
  </mat-select>
</mat-form-field>
