import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { UserDetailsService } from '../../../core/services/user-details/user-details.service';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective implements OnInit {
  permission!: string[];

  @Input() set appPermission(permission: string[]) {
    this.permission = permission;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userDetailsService: UserDetailsService,
  ) {}

  ngOnInit(): void {
    const hasAccess = this.checkPermissionAccess(this.permission);
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  checkPermissionAccess(routePermission: string[]): boolean {
    // Check if user has permission for the route
    if (routePermission != null) {
      return this.userDetailsService.userDetails.permissions.some((permission) =>
        routePermission.includes(permission.name),
      );
    }

    return true;
  }
}
