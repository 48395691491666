import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { StorageTokens } from '../../../shared/configs/storage-tokens.config';
import { DynamicEnvConfigService } from '../dynamic-env-config/dynamic-env-config.service';
import { ApiService } from '../http/api.service';
import { StorageService } from '../storage/storage.service';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService extends ApiService {
  messages$: Observable<any>;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    dynamicEnvConfigService: DynamicEnvConfigService,
    httpClient: HttpClient,
    private storageService: StorageService,
  ) {
    super(dynamicEnvConfigService, httpClient);
    this.messages$ = this.angularFireMessaging.messages;
  }

  deleteFcmToken(): Observable<boolean> {
    const token = this.storageService.getFromStorage('local', StorageTokens.fcmToken);

    return this.angularFireMessaging.deleteToken(token);
  }

  getFcmTokenFromFirebase(): Observable<string | null> {
    const { permission } = Notification;

    if (permission === 'granted') {
      return this.angularFireMessaging.requestToken.pipe(
        switchMap((token) => this.updateFcmId(token)),
      );
    }

    if (permission !== 'denied') {
      Notification.requestPermission().then((notificationPermission) => {
        if (notificationPermission === 'granted') {
          return this.angularFireMessaging.requestToken.pipe(
            switchMap((token) => this.updateFcmId(token)),
          );
        }

        return of(null);
      });
    }

    return of(null);
  }

  private updateFcmId(fcmToken: string | null): Observable<string | null> {
    this.storageService.addToStorage('local', StorageTokens.fcmToken, fcmToken);

    return this.put<string | null>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/user-fcm`,
      {
        fcm_id: fcmToken,
      },
    );
  }
}
