import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HANDLED_ERRORS } from '../../../../shared/configs/http-context.config';
import { StorageTokens } from '../../../../shared/configs/storage-tokens.config';
import { ApiResponse } from '../../../../shared/models/api-response';
import { ForgotPassword } from '../../../../shared/models/forgot-password.dto';
import { StorageKey } from '../../../../shared/models/storage-key.type';
import { DynamicEnvConfigService } from '../../dynamic-env-config/dynamic-env-config.service';
import { PushNotificationService } from '../../push-notification/push-notification.service';
import { StorageService } from '../../storage/storage.service';
import { UserDetailsService } from '../../user-details/user-details.service';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {
  constructor(
    dynamicEnvConfigService: DynamicEnvConfigService,
    httpClient: HttpClient,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private storageService: StorageService,
    private userDetailsService: UserDetailsService,
  ) {
    super(dynamicEnvConfigService, httpClient);
  }

  get isLoggedIn(): boolean {
    return !!this.userDetailsService.userDetails;
  }

  get token(): string {
    return this.storageService.getFromStorage('local', StorageTokens.accessToken1);
  }

  checkCentreActivated(): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.offlineService}/public/api/v1/is-activated`,
    );
  }

  dualLogin(loginDetails: any): Observable<any> {
    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/login/multi`,
      loginDetails,
    ).pipe(
      tap((res) => {
        this.resetLocalStorage();
        // Saving new values to storage
        this.storageService.addToStorage('local', StorageTokens.accessToken1, res.token_1);
        this.storageService.addToStorage('local', StorageTokens.accessToken2, res.token_2);
        this.userDetailsService.setUserDetails(res.data.user_1);
        this.userDetailsService.setUserDetails(res.data.user_2, 2);
      }),
    );
  }

  getUserToken(tokenType: number): string {
    let accessToken = '';
    if (tokenType === 1) {
      accessToken = this.storageService.getFromStorage('local', StorageTokens.accessToken1);
    } else if (tokenType === 2) {
      accessToken = this.storageService.getFromStorage('local', StorageTokens.accessToken2);
    }

    return accessToken;
  }

  fetchLoginOtp(formData: any, handledErrors: number[] = []): Observable<any> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/login-resend-otp`,
      formData,
      {
        context,
      },
    );
  }

  fetchOtp(formData: any, handledErrors: number[] = []): Observable<ApiResponse<ForgotPassword>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<ForgotPassword>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/forgot-password`,
      formData,
      {
        context,
      },
    );
  }

  getAccessToken(tokenNumber: number = 1): string {
    return this.storageService.getFromStorage(
      'local',
      tokenNumber === 1 ? StorageTokens.accessToken1 : StorageTokens.accessToken2,
    );
  }

  logout(): Observable<any> {
    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/logout`,
    ).pipe(
      tap(() => {
        this.logoutLocally();
        this.router.navigate(['login']);
      }),
    );
  }

  logoutLocally(): void {
    this.pushNotificationService.deleteFcmToken().pipe(take(1)).subscribe();
    this.userDetailsService.removeUserDetails();
    this.resetLocalStorage();
  }

  registerUser(registrationDetails: any): Observable<any> {
    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/user/store`,
      registrationDetails,
    );
  }

  resendOtp(formData: any, handledErrors: number[] = []): Observable<ApiResponse<ForgotPassword>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<ForgotPassword>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/resend-otp`,
      formData,
      {
        context,
      },
    );
  }

  resetPassword(
    formData: any,
    handledErrors: number[] = [],
  ): Observable<ApiResponse<ForgotPassword>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<ForgotPassword>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/reset-password`,
      formData,
      {
        context,
      },
    );
  }

  sendRegistrationOtp(formData: any, handledErrors: number[] = []): Observable<any> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/register-resend-otp`,
      formData,
      {
        context,
      },
    );
  }

  singleLogin(loginDetails: any): Observable<any> {
    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/login`,
      loginDetails,
    ).pipe(
      tap((res) => {
        this.resetLocalStorage();
        // Saving new values to storage
        this.storageService.addToStorage('local', StorageTokens.accessToken1, res.token);
        this.userDetailsService.setUserDetails(res.data);
      }),
    );
  }

  validatePersonalDetails(personalDetails: any, handledErrors: number[] = []): Observable<any> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/user/validate`,
      personalDetails,
      {
        context,
      },
    );
  }

  verifyLoginOtp(loginDetails: any): Observable<any> {
    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/login-using-otp`,
      loginDetails,
    ).pipe(
      tap((res) => {
        // Saving, clearing & restoring values from storage
        const storageItemsToBeStored: StorageTokens[] = [StorageTokens.language];
        const storageItemsList = this.retrieveValuesFromStorage('local', storageItemsToBeStored);
        this.storageService.clearStorage('local');
        this.restoreValuesToStorage('local', storageItemsList);

        // Saving new values to storage
        this.storageService.addToStorage('local', StorageTokens.accessToken1, res.token);
        this.userDetailsService.setUserDetails(res.data);
      }),
    );
  }

  verifyOtp(formData: any, handledErrors: number[] = []): Observable<ApiResponse<ForgotPassword>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<ForgotPassword>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/otp-verify`,
      formData,
      {
        context,
      },
    );
  }

  verifyRegistrationOtp(formData: any, handledErrors: number[] = []): Observable<any> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<any>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/register-using-otp`,
      formData,
      {
        context,
      },
    );
  }

  private resetLocalStorage(): void {
    // Saving, clearing & restoring values from storage
    const storageItemsToBeStored: StorageTokens[] = [StorageTokens.language];
    const storageItemsList = this.retrieveValuesFromStorage('local', storageItemsToBeStored);
    this.storageService.clearStorage('local');
    this.restoreValuesToStorage('local', storageItemsList);
  }

  private restoreValuesToStorage(storageType: StorageKey, storageItemList: any[]): void {
    storageItemList.forEach(({ key, value }) => {
      this.storageService.addToStorage(storageType, key, value);
    });
  }

  private retrieveValuesFromStorage(storageType: StorageKey, keyList: StorageTokens[]): any[] {
    return keyList.map((key) => ({
      key,
      value: this.storageService.getFromStorage(storageType, key),
    }));
  }
}
