export enum AnalyticsEventCategory {
  community = 'community',
  homepage = 'homepage',
  info = 'info',
  job = 'job',
  lesson = 'lesson',
  library = 'library',
  login = 'login',
  module = 'module',
  pointLink = 'point_link',
  profileSession = 'profile_session',
  scoreLink = 'score_link',
  subject = 'subject',
}
