import { HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HANDLED_ERRORS } from '../../../../shared/configs/http-context.config';
import { MasterListType } from '../../../../shared/configs/master-list-type.config';
import { ApiResponse } from '../../../../shared/models/api-response';
import { Learner } from '../../../../shared/models/batches';
import { Batch } from '../../../../shared/models/batches/batch.model';
import { CreditDetails } from '../../../../shared/models/credit/index';
import { TicketComment } from '../../../../shared/models/help/ticket-comment.model';
import { FacilitatorBatch } from '../../../../shared/models/home';
import { MasterListItem, MasterListsObj } from '../../../../shared/models/master-list';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService extends ApiService {
  addReportComment(
    formData: any,
    handledErrors: number[] = [],
  ): Observable<ApiResponse<TicketComment>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<TicketComment>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.commonService}/public/api/v1/report-comment`,
      formData,
      {
        context,
      },
    );
  }

  fetchBatch(batchId: string): Observable<ApiResponse<Batch>> {
    return this.get<ApiResponse<Batch>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.commonService}/public/api/v1/batch/${batchId}`,
    );
  }

  fetchBatchesUnderCentre(centreId: string): Observable<ApiResponse<Batch[]>> {
    return this.get<ApiResponse<Batch[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/centre/${centreId}/batch`,
    );
  }

  fetchCentresUnderDistrict(districtId: string): Observable<ApiResponse<MasterListItem[]>> {
    return this.get<ApiResponse<MasterListItem[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/district/${districtId}/centres`,
    );
  }

  fetchCentresUnderOrganisation(organisationId: string): Observable<ApiResponse<MasterListItem[]>> {
    return this.get<ApiResponse<MasterListItem[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/organisation/${organisationId}/centre`,
    );
  }

  fetchCoursesUnderSubject(subjectId: string): Observable<ApiResponse<MasterListItem[]>> {
    return this.get<ApiResponse<MasterListItem[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/subject/${subjectId}/course`,
    );
  }

  fetchCoursesAndLessonsUnderSubject(subjectId: string): Observable<any> {
    return this.get<any>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/subject/${subjectId}/course-lesson`,
    );
  }

  fetchCredits(): Observable<CreditDetails> {
    return this.get<CreditDetails>(
      `${this.getApiBaseUrl()}/${this.apiUrls.commonService}/public/api/v1/get-credits`,
    );
  }

  fetchDistrictsUnderState(stateId: string): Observable<ApiResponse<MasterListItem[]>> {
    return this.get<ApiResponse<MasterListItem[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/state/${stateId}/district`,
    );
  }

  fetchFacilitatorBatchesUnderCentre(
    centreId: string,
  ): Observable<ApiResponse<FacilitatorBatch[]>> {
    return this.get<ApiResponse<FacilitatorBatch[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/centre/${centreId}/batch`,
    );
  }

  fetchLearnerWithOutBatch(batchId: string): Observable<ApiResponse<Learner[]>> {
    return this.get<ApiResponse<Learner[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/batch/${batchId}/students-without-batch`,
    );
  }

  fetchLessonsUnderCourse(subjectId: string, courseId: string): Observable<ApiResponse<any>> {
    return this.get<ApiResponse<any>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.commonService}/public/api/v1/community-lessons`,
      {
        params: this.getQueryStringParameters({
          subject_id: subjectId,
          course_id: courseId,
        }),
      },
    );
  }

  fetchMasterLists(masterListTypeList: MasterListType[]): Observable<MasterListsObj> {
    const masterListTypeParameterList: string[] = [];
    masterListTypeList.forEach((masterListType) => {
      masterListTypeParameterList.push(`&list_models[]=${masterListType}`);
    });

    return this.get<MasterListsObj>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/get-master-list?${masterListTypeParameterList.join('&')}`,
    );
  }

  fetchOrganisationsUnderState(stateId: string): Observable<ApiResponse<MasterListItem[]>> {
    return this.get<ApiResponse<MasterListItem[]>>(
      `${this.getApiBaseUrl()}/${
        this.apiUrls.commonService
      }/public/api/v1/state/${stateId}/organisation`,
    );
  }

  fetchRegistrationDropdowns(): Observable<MasterListsObj> {
    return this.get<MasterListsObj>(
      `${this.getApiBaseUrl()}/${this.apiUrls.userService}/public/api/v1/user/register`,
    );
  }

  fetchStudentsListForCommunityLearnerTag(
    searchKey: string,
    batchId: string,
  ): Observable<ApiResponse<any[]>> {
    return this.get<ApiResponse<any[]>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.communityService}/public/api/v1/user`,
      {
        params: this.getQueryStringParameters({
          search_key: searchKey,
          batch_id: batchId,
        }),
      },
    );
  }

  uploadFile(formData: FormData): Observable<Pick<ApiResponse, 'url'>> {
    const headers = new HttpHeaders();

    return this.post<Pick<ApiResponse, 'url'>>(
      `${this.getApiBaseUrl()}/${this.apiUrls.commonService}/public/api/v1/upload-file`,
      formData,
      {
        headers,
      },
    );
  }
}
