<div class="core-container" fxLayout="column" fxLayoutAlign="center center">
  <!-- Language Selection -->
  <div class="language-select" fxFlexAlign="end">
    <app-language-select></app-language-select>
  </div>

  <div class="spacer" fxHide fxShow.xs></div>

  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Quest Heading -->
  <h1 class="core-main-title">{{ 'registration_stage_1.welcome' | translate }}</h1>

  <!-- Quest Caption -->
  <h3>{{ 'registration_stage_1.about_app' | translate }}</h3>

  <!-- Title -->
  <h2 class="core-sub-title underline">{{ 'registration_stage_1.choose_account' | translate }}</h2>

  <!-- Account Types -->
  <div fxLayout="row wrap" fxLayoutAlign="space-around center">
    <!-- Learner -->
    <mat-card
      [ngClass]="{ selected_account: userTypeId === userType.learner }"
      routerLink="../account_details/learner"
    >
      <mat-card-content>
        <img src="assets/images/registration/learner.png" />
        <p>{{ 'registration_stage_1.learner' | translate }}</p>
      </mat-card-content>
    </mat-card>

    <!-- Facilitator -->
    <mat-card
      [ngClass]="{ selected_account: userTypeId === userType.facilitator }"
      routerLink="../account_details/facilitator"
      *appOffline="'hide'"
    >
      <mat-card-content>
        <img src="assets/images/registration/facilitator.png" />
        <p>{{ 'registration_stage_1.facilitator' | translate }}</p>
      </mat-card-content>
    </mat-card>

    <!-- Alumni -->
    <mat-card
      [ngClass]="{ selected_account: userTypeId === userType.alumni }"
      routerLink="../account_details/alumni"
    >
      <mat-card-content>
        <img src="assets/images/registration/alumni.png" />
        <p>{{ 'registration_stage_1.alumni' | translate }}</p>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Have Account -->
  <div
    class="link-text"
    fxFlexOffset="40px"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <span>
      {{ 'registration_stage_1.have_account' | translate }}
    </span>
    <button mat-flat-button color="accent" routerLink="/login">
      {{ 'registration_stage_1.login' | translate }}
    </button>
  </div>

  <!-- Help -->
  <div class="link-text help" fxFlexOffset="15px" (click)="onClickHelp()">
    {{ 'login.help' | translate }}
  </div>
</div>
